"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const Hooks_1 = require("@/Hooks");
const react_redux_1 = require("react-redux");
const header_1 = require("../Redux/Actions/header");
const continents_1 = require("../Constants/continents");
const useHeaderData = () => {
    var _a;
    const page = (0, Hooks_1.useCurrentPage)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const [fetchedContinentHeader, setFetchedContinentHeader] = (0, react_1.useState)();
    const headerData = (0, react_redux_1.useSelector)((state) => state.header.header);
    const pathname = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname;
    const getContinent = () => {
        if (continents_1.NORTH_AMERICA.some(el => pathname.includes(el))) {
            return continents_1.NORTH_AMERICA[0];
        }
        if (continents_1.EUROPE.some(el => pathname.includes(el))) {
            return continents_1.EUROPE[0];
        }
        return 'corporate';
    };
    const getHeader = () => {
        var _a, _b;
        dispatch((0, header_1.fetchHeader)({
            pageId: (_b = (_a = page.contentLink) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString(),
            callback: () => {
                if (pathname) {
                    setFetchedContinentHeader(getContinent());
                }
            }
        }));
    };
    (0, react_1.useEffect)(() => {
        if (headerData) {
            return;
        }
        if (page) {
            getHeader();
        }
    }, [page, headerData]);
    (0, react_1.useEffect)(() => {
        const newRegion = getContinent();
        if ((page === null || page === void 0 ? void 0 : page.url) && fetchedContinentHeader && newRegion !== fetchedContinentHeader) {
            getHeader();
        }
    }, [page === null || page === void 0 ? void 0 : page.url]);
    return headerData;
};
exports.default = useHeaderData;
