"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFamilyData = exports.PRODUCTION_STATUS_FILTER = exports.ACTIVE_FILTER_NA = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const nodes_1 = require("@/Endpoints/nodes");
const react_router_1 = require("react-router");
const Types_1 = require("@/Types");
const Utils_1 = require("@/Utils");
const Hooks_1 = require("@/Hooks");
const Constants_1 = require("@/Constants");
const currentPage_1 = require("@/Endpoints/currentPage");
exports.ACTIVE_FILTER_NA = 'Active';
exports.PRODUCTION_STATUS_FILTER = 'ProductionStatus';
const useFamilyData = () => {
    var _a;
    const locations = (0, react_router_1.useLocation)();
    const initialData = (0, Hooks_1.useInitialData)();
    const continent = (0, react_redux_1.useSelector)((state) => state.initialData.continent);
    const { isDesktopSmall4, isMobile } = (0, Hooks_1.useDevice)();
    const INITIAL_PAGESIZE = isMobile ? 4 : 20;
    const isEurope = Constants_1.EUROPE.includes((_a = initialData === null || initialData === void 0 ? void 0 : initialData.continent) === null || _a === void 0 ? void 0 : _a.toLowerCase());
    const cookieCatalogueLanguage = (0, Utils_1.getCookie)(Constants_1.Cookies.CATALOG_LANGUAGE_COOKIE_NAME);
    const languageParam = cookieCatalogueLanguage && isEurope ? JSON.parse(cookieCatalogueLanguage).code : (0, Utils_1.getLanguage)();
    const [error, setError] = (0, react_1.useState)();
    const [familyPageState, setFamilyPageState] = (0, react_1.useState)();
    const [families, setFamilies] = (0, react_1.useState)();
    const [productsAmount, setProductsAmount] = (0, react_1.useState)();
    const [isFamilyLoading, setIsFamilyLoading] = (0, react_1.useState)(true);
    const [filters, setFilters] = (0, react_1.useState)(null);
    const [isFirstRender, setIsFirstRender] = (0, react_1.useState)(true);
    const [filterParam, setFilterParam] = (0, react_1.useState)({});
    const [newPageSize, setNewPageSize] = (0, react_1.useState)(INITIAL_PAGESIZE);
    const [lastRequestParams, setLastRequestParams] = (0, react_1.useState)('');
    const [savedListingFilters, setSavedListingFilters] = (0, react_1.useState)('');
    const [currentPageData, setCurrentPageData] = (0, react_1.useState)();
    const isListingOrCompare = familyPageState === Types_1.EFamilyPageStates.LISTING || familyPageState === Types_1.EFamilyPageStates.COMPARE;
    const { breadcrumbs, name, description } = currentPageData || {};
    const setListingPageState = () => {
        setFamilyPageState(Types_1.EFamilyPageStates.LISTING);
        window.history.replaceState({}, '', `${window === null || window === void 0 ? void 0 : window.location.pathname}${savedListingFilters}`);
    };
    const getData = (activeFilters, pageSize, languageCode) => __awaiter(void 0, void 0, void 0, function* () {
        setIsFamilyLoading(true);
        setError(undefined);
        const [result, pageResult] = yield Promise.all([
            (0, nodes_1.getNodeData)(locations.pathname, pageSize, activeFilters, languageCode),
            isEurope ? (0, currentPage_1.getCurrentPageData)((0, Utils_1.changeLanguageLocaleInUrl)(locations.pathname, languageParam)) : null
        ]);
        const { content, totalMatching, breadcrumbs } = result;
        if (result instanceof Error) {
            setError(result);
            if (!(result === null || result === void 0 ? void 0 : result.message.includes(Constants_1.CANCELED))) {
                setIsFamilyLoading(false);
            }
        }
        else {
            content && setFamilies(content === null || content === void 0 ? void 0 : content.variants);
            totalMatching >= 0 && setProductsAmount(totalMatching);
            if (isFirstRender || (filters === null || filters === void 0 ? void 0 : filters.length) === 0) {
                const newFilters = [];
                if (content === null || content === void 0 ? void 0 : content.queriedFilters) {
                    content.queriedFilters.forEach(item => {
                        var _a;
                        const newFilter = (0, Utils_1.getFilters)(item);
                        if (((_a = newFilter.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                            newFilters.push(newFilter);
                        }
                    });
                }
                if (content === null || content === void 0 ? void 0 : content.attributes) {
                    content.attributes.forEach(attribute => {
                        var _a;
                        const newFilter = (0, Utils_1.getFilters)(attribute);
                        if (((_a = newFilter.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                            newFilters.push(newFilter);
                        }
                    });
                }
                setFilters(newFilters);
            }
        }
        setCurrentPageData(pageResult);
        setIsFamilyLoading(false);
        setIsFirstRender(false);
    });
    (0, react_1.useEffect)(() => {
        if (isDesktopSmall4 && familyPageState === Types_1.EFamilyPageStates.COMPARE) {
            setListingPageState();
        }
    }, [isDesktopSmall4]);
    (0, react_1.useEffect)(() => {
        var _a;
        const isNorthAmerica = Constants_1.NORTH_AMERICA.includes(continent === null || continent === void 0 ? void 0 : continent.toLowerCase());
        const isEurope = Constants_1.EUROPE.includes((_a = initialData === null || initialData === void 0 ? void 0 : initialData.continent) === null || _a === void 0 ? void 0 : _a.toLowerCase());
        const activeFilterName = isNorthAmerica
            ? exports.ACTIVE_FILTER_NA
            : (0, Utils_1.getActiveFilterTranslate)(isEurope ? languageParam : (0, Utils_1.getLanguage)());
        if (isListingOrCompare && isFirstRender && continent) {
            const search = window.location.search;
            if (typeof search === 'string' && search.trim() === '') {
                window.history.replaceState({}, '', `${window === null || window === void 0 ? void 0 : window.location.pathname}?${exports.PRODUCTION_STATUS_FILTER}=${activeFilterName}`);
            }
            setLastRequestParams(JSON.stringify((0, Utils_1.generateParam)()) + newPageSize);
            getData((0, Utils_1.generateParam)(), newPageSize || INITIAL_PAGESIZE, languageParam);
        }
    }, [isListingOrCompare, continent]);
    (0, react_1.useEffect)(() => {
        const newRequestParams = JSON.stringify(filterParam) + newPageSize;
        if (!isFirstRender && lastRequestParams !== newRequestParams && isListingOrCompare) {
            setLastRequestParams(newRequestParams);
            getData(filterParam, newPageSize || INITIAL_PAGESIZE, languageParam);
        }
    }, [isListingOrCompare, filterParam, newPageSize, languageParam]);
    return {
        familyPageState,
        setFamilyPageState,
        productsAmount,
        filters,
        families,
        error,
        isFamilyLoading,
        setListingPageState,
        newPageSize,
        setNewPageSize,
        setFilterParam,
        setSavedListingFilters,
        breadcrumbs,
        name,
        description
    };
};
exports.useFamilyData = useFamilyData;
exports.default = exports.useFamilyData;
