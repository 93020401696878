"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkBrowser = void 0;
const checkBrowser = () => {
    var _a;
    const browsers = {
        isFireFox: false
    };
    if (typeof window === `undefined` || typeof navigator === `undefined`)
        return browsers;
    if (((_a = navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) === null || _a === void 0 ? void 0 : _a.search('Firefox')) > -1) {
        browsers.isFireFox = true;
    }
    return browsers;
};
exports.checkBrowser = checkBrowser;
