"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IContentLink = exports.Link = void 0;
const Link = (link) => {
    if (!link)
        return;
    const typedLink = { href: link.href, target: link.target };
    return typedLink;
};
exports.Link = Link;
const IContentLink = (page) => {
    if (!page)
        return;
    const typedLink = { href: '/' + page.url.match(/\/\/[^/]+\/([^.]+)/)[1] };
    return typedLink;
};
exports.IContentLink = IContentLink;
