"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled = __importStar(require("./styled"));
const useWindowSize_1 = require("../../../Hooks/useWindowSize");
const Theme_1 = require("../../../Theme/Theme");
const DOTS = '...';
const PageSelect = ({ pageIndex, setPageIndex, totalPages, isSmall }) => {
    const [pagesArray, setPagesArray] = (0, react_1.useState)([]);
    const { width } = (0, useWindowSize_1.useWindowSize)();
    const [numberAmount, setNumberAmount] = (0, react_1.useState)(isSmall ? 2 : 4);
    (0, react_1.useEffect)(() => {
        const array = [];
        const totalPagesLength = totalPages - 1;
        let sideStep;
        if (totalPages <= 6) {
            for (let i = 0; i <= totalPagesLength; i++) {
                array.push(i);
            }
            setPagesArray(array);
            return;
        }
        if (numberAmount === 4) {
            sideStep = 2;
        }
        if (numberAmount === 3) {
            sideStep = 1;
        }
        if (numberAmount === 2) {
            sideStep = 0;
        }
        if (pageIndex < numberAmount) {
            for (let i = 0; i <= numberAmount; i++) {
                array.push(i);
            }
            array.push(DOTS);
            array.push(totalPagesLength);
            setPagesArray(array);
            return;
        }
        if (pageIndex > totalPagesLength - numberAmount) {
            array.push(0);
            array.push(DOTS);
            for (let i = totalPagesLength - numberAmount; i <= totalPagesLength; i++) {
                array.push(i);
            }
            setPagesArray(array);
            return;
        }
        if (array.length === 0) {
            array.push(0);
            !isSmall && array.push(DOTS);
            if (isSmall) {
                for (let i = pageIndex; i <= pageIndex + 1; i++) {
                    array.push(i);
                }
            }
            else {
                for (let i = pageIndex - sideStep; i <= pageIndex + sideStep; i++) {
                    array.push(i);
                }
            }
            array.push(DOTS);
            array.push(totalPagesLength);
            setPagesArray(array);
        }
    }, [totalPages, pageIndex, numberAmount]);
    (0, react_1.useEffect)(() => {
        if (width < Theme_1.theme.breakpoints.lg) {
            setNumberAmount(3);
        }
        else {
            setNumberAmount(isSmall ? 2 : 4);
        }
    }, [width]);
    return (react_1.default.createElement(styled.Container, { isSmall: isSmall }, !!totalPages && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(styled.ArrowLeftContainer, { disabled: pageIndex === 0, onClick: () => setPageIndex(prev => prev - 1), isSmall: isSmall },
            react_1.default.createElement(styled.ArrowLeftIcon, null)),
        react_1.default.createElement(styled.Contents, null, !!pagesArray.length &&
            pagesArray.map((page, index) => {
                if (page === DOTS) {
                    return (react_1.default.createElement(styled.PageButton, { key: index, dots: true }, page));
                }
                return (react_1.default.createElement(styled.PageButton, { key: index, selected: pageIndex === page, onClick: () => setPageIndex(+page), isSmall: isSmall }, `${+page + 1}`));
            })),
        react_1.default.createElement(styled.ArrowRightContainer, { disabled: pageIndex + 1 === totalPages, onClick: () => setPageIndex(prev => prev + 1), isSmall: isSmall },
            react_1.default.createElement(styled.ArrowRightIcon, null))))));
};
exports.default = PageSelect;
