"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoaded = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    loaded: false
};
const ContentSlice = (0, toolkit_1.createSlice)({
    name: 'content',
    initialState,
    reducers: {
        setLoaded: (state, { payload }) => {
            state.loaded = payload;
        }
    }
});
exports.setLoaded = ContentSlice.actions.setLoaded;
exports.default = ContentSlice.reducer;
