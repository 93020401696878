"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseYupError = void 0;
const parseYupError = (enumObj, errors) => {
    const fieldErrors = errors.reduce((acc, err) => {
        if (Object.values(enumObj).includes(err.field)) {
            acc[err.field] = {
                message: err.message,
                error: true
            };
        }
        return acc;
    }, {});
    return fieldErrors;
};
exports.parseYupError = parseYupError;
