"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.miniCartVisibility = void 0;
const Types_1 = require("Types");
const miniCartVisibility = (userData, cartPage, isNorthAmerica) => {
    var _a;
    let showMiniCartIcon = true;
    const haveRequiredRole = (userData === null || userData === void 0 ? void 0 : userData.roles) &&
        userData.roles.some(role => role === (isNorthAmerica ? Types_1.UserRoles.NA_PLACE_ORDERS : Types_1.UserRoles.EU_PLACE_ORDERS));
    if (((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname) === cartPage ||
        !userData.isAuthenticated ||
        !haveRequiredRole ||
        !userData.hasAxaptaData) {
        showMiniCartIcon = false;
    }
    return showMiniCartIcon;
};
exports.miniCartVisibility = miniCartVisibility;
