"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productionStatusArray = exports.customerSpecificArray = exports.stockValues = exports.currencies = void 0;
exports.currencies = {
    '€': 'EUR',
    $: 'USD',
    CAD: 'CAD'
};
exports.stockValues = {
    IN_STOCK: 'In stock',
    NOT_IN_STOCK: 'Not in stock',
};
exports.customerSpecificArray = ['No', 'Oem only', 'Client only'];
exports.productionStatusArray = ['Active', 'No longer in production', 'Available later'];
