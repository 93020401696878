"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacing = void 0;
exports.spacing = {
    xs: 1,
    xs1: 3,
    s0: 2,
    s1: 4,
    s2: 8,
    s3: 12,
    s4: 16,
    s5: 20,
    s6: 10,
    s7: 6,
    s8: 15,
    s9: 26,
    m1: 24,
    m2: 28,
    m3: 32,
    m4: 36,
    m5: 40,
    m6: 44,
    l1: 48,
    l2: 56,
    l3: 60,
    l4: 64,
    l5: 68,
    xl1: 80,
    xl2: 110,
    xl3: 140
};
