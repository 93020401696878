"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DarkOverlayLevels = void 0;
var DarkOverlayLevels;
(function (DarkOverlayLevels) {
    DarkOverlayLevels["NO"] = "0";
    DarkOverlayLevels["MIN"] = "10";
    DarkOverlayLevels["MD"] = "20";
    DarkOverlayLevels["LG"] = "30";
    DarkOverlayLevels["MAX"] = "40";
})(DarkOverlayLevels = exports.DarkOverlayLevels || (exports.DarkOverlayLevels = {}));
