"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchTitle = void 0;
const Types_1 = require("Types");
const getSearchTitle = (type, isTabs, isGlobal, showMoreButton) => {
    switch (type) {
        case Types_1.SearchTypeEnum.PRODUCTS:
            return '/haldex/search_page/search_products';
        case Types_1.SearchTypeEnum.PAGES:
            return '/haldex/search_page/search_pages';
        case Types_1.SearchTypeEnum.PRESS_RELEASES:
            return '/haldex/search_page/search_press_releases';
        case Types_1.SearchTypeEnum.NEWS:
            return isTabs ? '/haldex/search_page/search_news' : '/haldex/search_page/search_news_stories';
        case Types_1.SearchTypeEnum.REPORTS:
            return isGlobal ? '/haldex/search_page/search_reports' : '/haldex/search_page/search_documents';
        case Types_1.SearchTypeEnum.CROSS_REFERENCE:
            return '/haldex/search_page/search_cross_references';
        case Types_1.SearchTypeEnum.TAGS:
            return showMoreButton ? '/haldex/search_page/tag_pages_articles' : '/haldex/search_page/tag_pages';
        default:
            return '';
    }
};
exports.getSearchTitle = getSearchTitle;
