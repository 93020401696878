"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Target = void 0;
var Target;
(function (Target) {
    Target["BLANK"] = "_blank";
    Target["SELF"] = "_self";
    Target["PARENT"] = "_parent";
    Target["TOP"] = "_top";
})(Target = exports.Target || (exports.Target = {}));
