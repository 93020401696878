"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Manufacturers = void 0;
var Manufacturers;
(function (Manufacturers) {
    Manufacturers["HALDEX"] = "haldex";
    Manufacturers["GRAU"] = "grau";
    Manufacturers["LIKE_NU"] = "like-nu";
    Manufacturers["LIKENU"] = "likenu";
    Manufacturers["MIDLAND"] = "midland";
    Manufacturers["REMAN"] = "reman";
})(Manufacturers = exports.Manufacturers || (exports.Manufacturers = {}));
