"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutToCols = exports.LayoutCols = void 0;
const Types_1 = require("Types");
var LayoutCols;
(function (LayoutCols) {
    LayoutCols[LayoutCols["SINGLE_COLUMN"] = 1] = "SINGLE_COLUMN";
    LayoutCols[LayoutCols["TWO_COLUMN"] = 2] = "TWO_COLUMN";
    LayoutCols[LayoutCols["THREE_COLUMN"] = 3] = "THREE_COLUMN";
    LayoutCols[LayoutCols["FOUR_COLUMN"] = 4] = "FOUR_COLUMN";
    LayoutCols[LayoutCols["THREE_COLUMN_HALVED"] = 5] = "THREE_COLUMN_HALVED";
})(LayoutCols = exports.LayoutCols || (exports.LayoutCols = {}));
// Will return number of cols depending on input layout from layout block.
const LayoutToCols = (layout, typedWidth) => {
    const typedLayout = layout;
    switch (typedLayout) {
        case LayoutCols.TWO_COLUMN:
            return 6;
        case LayoutCols.THREE_COLUMN:
            return 4;
        case LayoutCols.FOUR_COLUMN:
            return 3;
        case LayoutCols.SINGLE_COLUMN:
            if (typedWidth === Types_1.BlockWidthsEnum.ARTICLE_WIDTH) {
                return 8;
            }
            break;
        default:
            return 12;
    }
};
exports.LayoutToCols = LayoutToCols;
