"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typography = void 0;
exports.typography = {
    type: {
        primary: "'Roboto', Arial, sans-serif"
    },
    weight: {
        light: '300',
        regular: '400',
        medium: '500',
        bold: '700'
    },
    size: {
        defaultSize: 16,
        // from 0 to 20
        xs: 10,
        s0: 15,
        s1: 12,
        s2: 14,
        s3: 16,
        s4: 17,
        s5: 19,
        // from 21 to 40
        m1: 23,
        m2: 28,
        m3: 33,
        m4: 40,
        // from 41
        l1: 48,
        l2: 56,
        l3: 80
    },
    lineHeight: {
        // from 0 to 20
        s1: 12,
        s2: 14,
        s3: 16,
        s4: 18,
        s5: 20,
        s6: 21,
        s7: 15,
        s8: 17,
        s9: 19,
        // from 21 to 40
        m1: 24,
        m2: 27,
        m3: 30,
        m4: 34,
        m5: 36,
        m6: 21,
        // from 41
        l1: 44,
        l2: 56,
        l3: 110,
        l4: 150,
        l5: 62,
        l6: 88
    },
    letterSpacing: {
        s1: 0.03
    }
};
