"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatuses = void 0;
var OrderStatuses;
(function (OrderStatuses) {
    OrderStatuses["OPEN_ORDER"] = "Open order";
    OrderStatuses["OPEN"] = "Open";
    OrderStatuses["DELIVERED"] = "Delivered";
    OrderStatuses["CANCELLED"] = "Cancelled";
    OrderStatuses["INVOICED"] = "Invoiced";
    OrderStatuses["PAID"] = "Paid";
})(OrderStatuses = exports.OrderStatuses || (exports.OrderStatuses = {}));
