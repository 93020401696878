"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.putlistQuickorder = exports.ProductStatuses = exports.getPricesInfo = exports.getCustomerServiceNotes = exports.getExpressOrdersDaysOff = exports.setShippingOption = exports.setAllShippingDates = exports.setShippingDate = exports.addToCart = exports.setCartAmount = exports.getCartData = exports.emptyCart = exports.deleteFromCart = void 0;
const Types_1 = require("Types");
const request_1 = require("./request");
const Endpoints_1 = require("../Constants/Endpoints");
const cart_1 = require("../Redux/Actions/cart");
const deleteFromCart = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    const body = {
        data: {
            Code: payload.code
        }
    };
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.REMOVE_ITEM, request_1.RequestType.DELETE, body);
        return result.data;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.deleteFromCart = deleteFromCart;
const emptyCart = ({ dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.EMPTY, request_1.RequestType.DELETE);
        dispatch((0, cart_1.setCartData)(result.data.cart));
    }
    catch (error) {
        console.log('error', error);
    }
});
exports.emptyCart = emptyCart;
const getCartData = (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.GET);
        dispatch((0, cart_1.setCartData)(result.data));
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.getCartData = getCartData;
const setCartAmount = ({ setIsLoading, payload, dispatch, productId }) => __awaiter(void 0, void 0, void 0, function* () {
    setIsLoading(true);
    payload.action = Types_1.CartActions.SetItem;
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.PUT, request_1.RequestType.PUT, payload, productId);
        dispatch((0, cart_1.setCartData)(result.data.cart));
    }
    catch (error) {
        console.error('error', error);
    }
    setIsLoading(false);
});
exports.setCartAmount = setCartAmount;
const addToCart = ({ payloads, setIsLoading, dispatch, onSuccess }) => __awaiter(void 0, void 0, void 0, function* () {
    setIsLoading(true);
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.PUTLIST, request_1.RequestType.PUT, payloads, undefined, true);
        dispatch((0, cart_1.setCartData)(result.data));
        onSuccess && onSuccess();
    }
    catch (error) {
        console.error('error', error);
    }
    setIsLoading(false);
});
exports.addToCart = addToCart;
const setShippingDate = ({ setIsLoading, payload }) => __awaiter(void 0, void 0, void 0, function* () {
    setIsLoading(true);
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.ITEM_SHIPPING_DATE, request_1.RequestType.POST, payload);
    }
    catch (error) {
        console.error('error', error);
    }
    setIsLoading(false);
});
exports.setShippingDate = setShippingDate;
const setAllShippingDates = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.ALL_SHIPPING_DATES, request_1.RequestType.POST, payload);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.setAllShippingDates = setAllShippingDates;
const setShippingOption = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.SET_SHIPPING_OPTION, request_1.RequestType.POST, payload);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.setShippingOption = setShippingOption;
const getExpressOrdersDaysOff = (pageRef) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.GET_EXPRESS_ORDERS_DAYS_OFF + `?pageRef=${pageRef}`);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.getExpressOrdersDaysOff = getExpressOrdersDaysOff;
const getCustomerServiceNotes = (continent) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.GET_CUSTOMER_SERVICE_NOTES + `?continent=${continent}`);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.getCustomerServiceNotes = getCustomerServiceNotes;
const getPricesInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.GET_PRICES_INFO);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.getPricesInfo = getPricesInfo;
var ProductStatuses;
(function (ProductStatuses) {
    ProductStatuses[ProductStatuses["Unknown"] = 0] = "Unknown";
    ProductStatuses[ProductStatuses["Success"] = 1] = "Success";
    ProductStatuses[ProductStatuses["ProductNotFound"] = 2] = "ProductNotFound";
    ProductStatuses[ProductStatuses["ProductHasNoPrice"] = 3] = "ProductHasNoPrice";
    ProductStatuses[ProductStatuses["UnknownCartUpdateAction"] = 4] = "UnknownCartUpdateAction";
    ProductStatuses[ProductStatuses["CartItemNotFound"] = 5] = "CartItemNotFound";
})(ProductStatuses = exports.ProductStatuses || (exports.ProductStatuses = {}));
const putlistQuickorder = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.CartEndpoints.PUTLIST_QUICKORDER, request_1.RequestType.PUT, payload);
        return result;
    }
    catch (error) {
        console.error('error', error);
    }
});
exports.putlistQuickorder = putlistQuickorder;
