"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepActions = void 0;
const react_1 = __importStar(require("react"));
const svg_components_1 = require("../../../../assets/svg-components");
const styled = __importStar(require("./styled"));
const styled_components_1 = require("styled-components");
const Utils_1 = require("../../../../Utils");
var StepActions;
(function (StepActions) {
    StepActions["PLUS"] = "plus";
    StepActions["MINUS"] = "minus";
})(StepActions = exports.StepActions || (exports.StepActions = {}));
const INTERVAL_TIME = 50;
const HOLD_TIMEOUT = 300;
const StepController = ({ error, step, setValue, min, max }) => {
    const theme = (0, styled_components_1.useTheme)();
    const [timeoutState, setTimeoutState] = (0, react_1.useState)();
    const [intervalState, setIntervalState] = (0, react_1.useState)();
    const stepAction = (action) => {
        const digitsAfterDecimalInStep = (0, Utils_1.digitsAfterDecimal)(step);
        if (action === StepActions.PLUS && step) {
            if ((0, Utils_1.isNum)(max)) {
                setValue(prev => {
                    const prevNum = Number(prev);
                    if (Number((prevNum + step).toFixed(digitsAfterDecimalInStep)) <= max) {
                        return (prevNum + step).toFixed(digitsAfterDecimalInStep);
                    }
                    else {
                        return prev;
                    }
                });
            }
            else {
                setValue(prev => (Number(prev) + step).toFixed(digitsAfterDecimalInStep));
            }
        }
        if (action === StepActions.MINUS && step) {
            if ((0, Utils_1.isNum)(min)) {
                setValue(prev => {
                    const prevNum = Number(prev);
                    if (Number((prevNum - step).toFixed(digitsAfterDecimalInStep)) >= min) {
                        return (prevNum - step).toFixed(digitsAfterDecimalInStep);
                    }
                    else {
                        return prev;
                    }
                });
            }
            else {
                setValue(prev => (Number(prev) - step).toFixed(digitsAfterDecimalInStep));
            }
        }
    };
    const onMouseDown = (action) => {
        setTimeoutState(setTimeout(() => {
            setIntervalState(setInterval(() => {
                stepAction(action);
            }, INTERVAL_TIME));
        }, HOLD_TIMEOUT));
    };
    const onMouseUp = () => {
        clearTimeout(timeoutState);
        clearInterval(intervalState);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        (error === null || error === void 0 ? void 0 : error.error) && react_1.default.createElement(svg_components_1.ValidationErrorIcon, null),
        react_1.default.createElement(styled.NumberControls, null,
            react_1.default.createElement(styled.PlusControl, { onClick: () => stepAction(StepActions.PLUS), onMouseDown: () => onMouseDown(StepActions.PLUS), onMouseUp: onMouseUp },
                react_1.default.createElement(svg_components_1.TriangleArrowIcon, { fill: theme.palette.TYPE_LIGHT_BLACK })),
            react_1.default.createElement(styled.MinusControl, { onClick: () => stepAction(StepActions.MINUS), onMouseDown: () => onMouseDown(StepActions.MINUS), onMouseUp: onMouseUp },
                react_1.default.createElement(svg_components_1.TriangleArrowIcon, { fill: theme.palette.TYPE_LIGHT_BLACK })))));
};
exports.default = StepController;
