"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTINENT_COOKIE = exports.EUROPE = exports.NORTH_AMERICA = exports.Continents = void 0;
var Continents;
(function (Continents) {
    Continents["NORTH_AMERICA"] = "north-america";
    Continents["EUROPE"] = "europe";
    Continents["NORTHAMERICA"] = "NorthAmerica";
})(Continents = exports.Continents || (exports.Continents = {}));
exports.NORTH_AMERICA = ['na', 'north-america', 'northamerica'];
exports.EUROPE = ['eu', 'europe'];
exports.CONTINENT_COOKIE = 'Continent';
