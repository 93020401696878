"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setInitialData = exports.InitialSlice = exports.fetchInitial = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const app_1 = require("@/Endpoints/app");
const Constants_1 = require("@/Constants");
const initialState = {
    language: '',
    continent: '',
    date: {
        shortDatePattern: '',
        momentDateFormat: ''
    },
    pages: {
        searchPage: '',
        cartPage: '',
        catalogPage: '',
        documentSearchPage: '',
        myHaldexPage: '',
        myOrdersPage: '',
        myInvoicesPage: '',
        myCoreAnalysisPage: '',
        myCoreReportsPage: '',
        registrationPage: ''
    },
    userDropDownLinks: [],
    apiKeys: [],
    environment: null,
    isNorthAmerica: false
};
exports.fetchInitial = (0, toolkit_1.createAsyncThunk)('fetchInitData', (pageId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, app_1.getInitData)(pageId);
    const apiKeys = yield (0, app_1.getApiKeys)();
    return Object.assign(Object.assign({}, response), { apiKeys });
}));
exports.InitialSlice = (0, toolkit_1.createSlice)({
    name: 'initialData',
    initialState,
    reducers: {
        setInitialData: (state, action) => {
            var _a, _b, _c;
            state.language = action.payload.language;
            state.continent = action.payload.continent;
            state.date = Object.assign(Object.assign({}, action.payload.date), { momentDateFormat: (_b = (_a = action.payload.date) === null || _a === void 0 ? void 0 : _a.shortDatePattern) === null || _b === void 0 ? void 0 : _b.toUpperCase() });
            state.pages = action.payload.pages;
            state.userDropDownLinks = action.payload.userDropDownLinks;
            state.apiKeys = action.payload.apiKeys;
            state.environment = action.payload.environment;
            state.isNorthAmerica = Constants_1.NORTH_AMERICA.includes((_c = action.payload.continent) === null || _c === void 0 ? void 0 : _c.toLowerCase());
        }
    },
    extraReducers: builder => {
        builder.addCase(exports.fetchInitial.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            state.language = action.payload.language;
            state.continent = action.payload.continent;
            state.date = {
                shortDatePattern: (_a = action.payload.date) === null || _a === void 0 ? void 0 : _a.shortDatePattern,
                momentDateFormat: (_c = (_b = action.payload.date) === null || _b === void 0 ? void 0 : _b.shortDatePattern) === null || _c === void 0 ? void 0 : _c.toUpperCase()
            };
            state.pages = {
                searchPage: (_d = action.payload.pages) === null || _d === void 0 ? void 0 : _d.searchPage,
                cartPage: (_e = action.payload.pages) === null || _e === void 0 ? void 0 : _e.cartPage,
                catalogPage: (_f = action.payload.pages) === null || _f === void 0 ? void 0 : _f.catalogPage,
                documentSearchPage: (_g = action.payload.pages) === null || _g === void 0 ? void 0 : _g.documentSearchPage,
                myHaldexPage: (_h = action.payload.pages) === null || _h === void 0 ? void 0 : _h.myHaldexPage,
                myOrdersPage: (_j = action.payload.pages) === null || _j === void 0 ? void 0 : _j.myOrdersPage,
                myInvoicesPage: (_k = action.payload.pages) === null || _k === void 0 ? void 0 : _k.myInvoicesPage,
                myCoreAnalysisPage: (_l = action.payload.pages) === null || _l === void 0 ? void 0 : _l.myCoreAnalysisPage,
                myCoreReportsPage: (_m = action.payload.pages) === null || _m === void 0 ? void 0 : _m.myCoreReportsPage,
                registrationPage: (_o = action.payload.pages) === null || _o === void 0 ? void 0 : _o.registrationPage
            };
            state.userDropDownLinks = action.payload.userDropDownLinks;
            state.apiKeys = action.payload.apiKeys;
            state.environment = action.payload.environment;
            state.isNorthAmerica = Constants_1.NORTH_AMERICA.includes((_p = action.payload.continent) === null || _p === void 0 ? void 0 : _p.toLowerCase());
        });
    }
});
exports.setInitialData = exports.InitialSlice.actions.setInitialData;
exports.default = exports.InitialSlice.reducer;
