"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertMinToDayHourMin = void 0;
const convertMinToDayHourMin = (min, t) => {
    const labels = {
        day: t('/haldex/coursePage/time_day'),
        days: t('/haldex/coursePage/time_days'),
        hour: t('/haldex/coursePage/time_hour'),
        hours: t('/haldex/coursePage/time_hours'),
        minute: t('/haldex/coursePage/time_minute'),
        minutes: t('/haldex/coursePage/time_minutes'),
        and: t('/haldex/coursePage/time_and')
    };
    const hours = min / 60;
    const days = hours / 24;
    const remaingDays = Math.floor(days);
    const remainingHours = Math.floor(hours - remaingDays * 24);
    const minutes = (hours - remainingHours - remaingDays * 24) * 60;
    const remainingMinutes = Math.round(minutes);
    let timeStr = '';
    const remaingDaysLabel = labels[remaingDays > 1 ? 'days' : 'day'];
    const remainingHoursLabel = labels[remainingHours > 1 ? 'hours' : 'hour'];
    const remainingMinutesLabel = labels[remainingMinutes > 1 ? 'minutes' : 'minute'];
    if (remaingDays) {
        timeStr += `${remaingDays} ${remaingDaysLabel}`;
    }
    if (remainingHours) {
        timeStr += `${remainingHours} ${remainingHoursLabel}`;
    }
    if (remainingMinutes) {
        timeStr += `${remainingHours ? ` ${labels.and} ` : ''}${remainingMinutes} ${remainingMinutesLabel}`;
    }
    return timeStr;
};
exports.convertMinToDayHourMin = convertMinToDayHourMin;
