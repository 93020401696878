"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguage = void 0;
const GetInitialData_1 = require("./GetInitialData");
const Constants_1 = require("@/Constants");
const isSsr = () => {
    return !!(0, GetInitialData_1.getInitialData)();
};
const getLanguageFromInitData = () => {
    const initData = (0, GetInitialData_1.getInitialData)();
    const language = initData === null || initData === void 0 ? void 0 : initData.Language;
    return language !== null && language !== void 0 ? language : '';
};
const getLanguageFromCurrentWindow = () => {
    var _a;
    const location = window === null || window === void 0 ? void 0 : window.location;
    if (!location) {
        // Assume global
        return Constants_1.DEFAULT_SITE_LOCALE;
    }
    const firstPath = location.pathname.split('/')[1];
    // For epi on page edit
    if (firstPath.toLowerCase() === 'episerver' && window) {
        const sessionStorage = (_a = window.sessionStorage) === null || _a === void 0 ? void 0 : _a.getItem('lastContext/episerver/cms/home');
        if (sessionStorage) {
            const parsedSessionStorage = JSON.parse(sessionStorage);
            if (parsedSessionStorage.language) {
                return parsedSessionStorage.language;
            }
        }
        return Constants_1.DEFAULT_SITE_LOCALE;
    }
    // Assume global if no route path
    return firstPath === '' ? Constants_1.DEFAULT_SITE_LOCALE : firstPath;
};
const getLanguage = () => {
    const url = isSsr() ? getLanguageFromInitData() || getLanguageFromCurrentWindow() : getLanguageFromCurrentWindow();
    return url;
};
exports.getLanguage = getLanguage;
