"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteInputHandler = exports.onPasteHandler = exports.generateErrorsAndNotifications = void 0;
const cart_1 = require("../Endpoints/cart");
const parseExcelParts_1 = require("./parseExcelParts");
const generateErrorsAndNotifications = (productStatuses, t, notifications, errors) => {
    const productErrorsObj = {};
    const productNotificationsObj = {};
    for (const key in productStatuses) {
        const { statusCode, haldexCode: { code }, updateQuantity } = productStatuses[key];
        if (statusCode === cart_1.ProductStatuses.Success) {
            productNotificationsObj[code] = {
                code: code,
                quantity: updateQuantity
            };
        }
        if (statusCode === cart_1.ProductStatuses.ProductNotFound) {
            productErrorsObj[code] = {
                code: code,
                quantity: updateQuantity,
                message: t('/haldex/quick_order/product_dont_exist')
            };
        }
        if (statusCode === cart_1.ProductStatuses.ProductHasNoPrice) {
            productErrorsObj[code] = {
                code: code,
                quantity: updateQuantity,
                message: t('/haldex/quick_order/no_price')
            };
        }
    }
    const prevNotifications = notifications || [];
    const newProductNotificationsObj = {};
    const prevErrors = errors || [];
    const newErrorsObj = {};
    const prevNotificationsObj = prevNotifications.reduce((acc, curValue) => {
        acc[curValue.code] = curValue;
        return acc;
    }, {});
    for (const key in prevNotificationsObj) {
        const { code, quantity } = prevNotificationsObj[key];
        if (productNotificationsObj === null || productNotificationsObj === void 0 ? void 0 : productNotificationsObj[key]) {
            newProductNotificationsObj[key] = {
                code: code,
                quantity: quantity + productNotificationsObj[key].quantity
            };
        }
        else {
            newProductNotificationsObj[key] = prevNotificationsObj[key];
        }
    }
    for (const key in productNotificationsObj) {
        if (!(prevNotificationsObj === null || prevNotificationsObj === void 0 ? void 0 : prevNotificationsObj[key])) {
            newProductNotificationsObj[key] = productNotificationsObj[key];
        }
    }
    const prevErrorsObj = prevErrors.reduce((acc, curValue) => {
        acc[curValue.code] = curValue;
        return acc;
    }, {});
    for (const key in prevErrorsObj) {
        const { code, quantity, message } = prevErrorsObj[key];
        if (productErrorsObj === null || productErrorsObj === void 0 ? void 0 : productErrorsObj[key]) {
            newErrorsObj[key] = {
                code: code,
                quantity: quantity + productErrorsObj[key].quantity,
                message: message
            };
        }
        else {
            newErrorsObj[key] = prevErrorsObj[key];
        }
    }
    for (const key in productErrorsObj) {
        if (!(prevErrorsObj === null || prevErrorsObj === void 0 ? void 0 : prevErrorsObj[key])) {
            newErrorsObj[key] = productErrorsObj[key];
        }
    }
    return {
        newProductNotificationsObj,
        newErrorsObj,
        productNotificationsObj
    };
};
exports.generateErrorsAndNotifications = generateErrorsAndNotifications;
const onPasteHandler = (e, index, setPartInputs, partInputs) => {
    e.preventDefault();
    const data = e.clipboardData.getData('Text');
    const result = (0, parseExcelParts_1.parseExcelParts)(data, partInputs, index);
    if (result === null || result === void 0 ? void 0 : result.length) {
        setPartInputs(prev => {
            const copy = [...prev];
            copy.splice(index, 1, ...result);
            return copy;
        });
    }
};
exports.onPasteHandler = onPasteHandler;
const deleteInputHandler = (index, isLoading, setPartInputs) => {
    if (!isLoading) {
        setPartInputs(prev => {
            const copy = [...prev];
            copy.splice(index, 1);
            return copy;
        });
    }
};
exports.deleteInputHandler = deleteInputHandler;
