"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchStringEnum = exports.SearchRegion = exports.SearchTypeEnum = void 0;
var SearchTypeEnum;
(function (SearchTypeEnum) {
    SearchTypeEnum["PAGES"] = "pages";
    SearchTypeEnum["NEWS"] = "news";
    SearchTypeEnum["PRESS_RELEASES"] = "pressReleases";
    SearchTypeEnum["PRODUCTS"] = "products";
    SearchTypeEnum["REPORTS"] = "reports";
    SearchTypeEnum["CROSS_REFERENCE"] = "crossReference";
    SearchTypeEnum["TAGS"] = "tags";
    SearchTypeEnum["ALL"] = "all";
})(SearchTypeEnum = exports.SearchTypeEnum || (exports.SearchTypeEnum = {}));
var SearchRegion;
(function (SearchRegion) {
    SearchRegion["GLOBAL"] = "Global";
})(SearchRegion = exports.SearchRegion || (exports.SearchRegion = {}));
var SearchStringEnum;
(function (SearchStringEnum) {
    SearchStringEnum["GET_ALL"] = "getPressReleases=true&getProducts=true&getPages=true&getReports=true&getCrossReferences=true&getTags=true";
    SearchStringEnum["GET_NEWS"] = "getPressReleases=true";
    SearchStringEnum["GET_PRESS_RELEASES"] = "getPressReleases=true";
    SearchStringEnum["GET_PRODUCTS"] = "getProducts=true";
    SearchStringEnum["GET_CROSS_REFERENCE"] = "&getCrossReferences=true";
    SearchStringEnum["GET_PAGES"] = "getPages=true";
    SearchStringEnum["GET_REPORTS"] = "getReports=true";
    SearchStringEnum["GET_TAGS"] = "getTags=true";
})(SearchStringEnum = exports.SearchStringEnum || (exports.SearchStringEnum = {}));
