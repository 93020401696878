"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remainingTime = void 0;
const remainingTime = (endDate) => {
    if (endDate) {
        const currentDate = new Date();
        const diff = new Date(endDate.getTime() - currentDate.getTime());
        const h = diff.getUTCHours().toString().padStart(2, '0');
        const m = diff.getUTCMinutes().toString().padStart(2, '0');
        const s = diff.getUTCSeconds().toString().padStart(2, '0');
        return {
            time: `${h}:${m}:${s}`,
            shortTime: diff.getUTCHours() === 0,
            isDisabled: currentDate.getTime() > endDate.getTime()
        };
    }
};
exports.remainingTime = remainingTime;
