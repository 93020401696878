"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartActions = exports.LOCATION_ID = exports.CurrencyCodeEnum = exports.ErrorTypes = void 0;
var ErrorTypes;
(function (ErrorTypes) {
    ErrorTypes["USER_NOT_ACTIVE"] = "UserIsNotActive";
    ErrorTypes["USER_NOT_AXAPTA_USER"] = "UserIsNotAxaptaUser";
    ErrorTypes["WRONG_DELIVERY_TYPE"] = "WrongDeliveryModeType";
    ErrorTypes["DROP_SHIP_NOT_ALLOWED"] = "DropShipIsNotAllowed";
    ErrorTypes["EMPTY_CART"] = "EmptyCart";
    ErrorTypes["UNDEFINED"] = "Undefined";
    ErrorTypes["GENERAL_ERROR"] = "GeneralError";
    ErrorTypes["PLACE_ORDER_IN_PROGRESS"] = "PlaceOrderIsInProgress";
    ErrorTypes["INVALID_SHIPPING"] = "InvalidShippingAddress";
    ErrorTypes["INVALID_BILLING"] = "InvalidBillingAddress";
    ErrorTypes["WRONG_SHIPMENT_DATE"] = "WrongShipmentDate";
    ErrorTypes["LACK_OF_ITEMS_IN_STOCK"] = "LackOfItemsInStock";
})(ErrorTypes = exports.ErrorTypes || (exports.ErrorTypes = {}));
var CurrencyCodeEnum;
(function (CurrencyCodeEnum) {
    CurrencyCodeEnum["EUR"] = "EUR";
    CurrencyCodeEnum["USD"] = "USD";
    CurrencyCodeEnum["GBP"] = "GBP";
})(CurrencyCodeEnum = exports.CurrencyCodeEnum || (exports.CurrencyCodeEnum = {}));
var LOCATION_ID;
(function (LOCATION_ID) {
    LOCATION_ID["CANADA"] = "2015001";
    LOCATION_ID["USA"] = "USA";
    LOCATION_ID["EUROPE"] = "1615001";
})(LOCATION_ID = exports.LOCATION_ID || (exports.LOCATION_ID = {}));
var CartActions;
(function (CartActions) {
    CartActions[CartActions["NoAction"] = 0] = "NoAction";
    CartActions[CartActions["UpdateItem"] = 1] = "UpdateItem";
    CartActions[CartActions["SetItem"] = 2] = "SetItem";
    CartActions[CartActions["RemoveItem"] = 3] = "RemoveItem";
    CartActions[CartActions["EmptyCart"] = 4] = "EmptyCart";
})(CartActions = exports.CartActions || (exports.CartActions = {}));
