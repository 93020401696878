"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoginModalState = exports.setIsLoginModalOpen = exports.loginModalSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoginModalOpen: false,
    useRedirect: true,
    refreshPage: false
};
exports.loginModalSlice = (0, toolkit_1.createSlice)({
    name: 'loginModalToggle',
    initialState,
    reducers: {
        setIsLoginModalOpen: (state, action) => {
            state.isLoginModalOpen = action.payload;
        },
        setLoginModalState: (state, action) => {
            state.isLoginModalOpen = action.payload.isLoginModalOpen;
            state.useRedirect = action.payload.useRedirect;
            state.refreshPage = action.payload.refreshPage;
        }
    }
});
_a = exports.loginModalSlice.actions, exports.setIsLoginModalOpen = _a.setIsLoginModalOpen, exports.setLoginModalState = _a.setLoginModalState;
exports.default = exports.loginModalSlice.reducer;
