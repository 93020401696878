"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveFilterTranslate = void 0;
const Constants_1 = require("@/Constants");
const ACTIVE_FILTER_EN = 'active';
const ACTIVE_FILTER_DE = 'aktiv';
const ACTIVE_FILTER_FR = 'actif';
const ACTIVE_FILTER_ES = 'activo';
const ACTIVE_FILTER_NL = 'aktief';
const ACTIVE_FILTER_PT = 'ativo';
const ACTIVE_FILTER_PL = 'dostępna/dostępny';
const ACTIVE_FILTER_IT = 'attivo';
const ACTIVE_FILTER_TR = 'active';
const getActiveFilterTranslate = (locale) => {
    let activeFilterName = '';
    switch (locale) {
        case Constants_1.LangNames.EN:
            activeFilterName = ACTIVE_FILTER_EN;
            break;
        case Constants_1.LangNames.DE:
            activeFilterName = ACTIVE_FILTER_DE;
            break;
        case Constants_1.LangNames.ES:
            activeFilterName = ACTIVE_FILTER_ES;
            break;
        case Constants_1.LangNames.FR:
            activeFilterName = ACTIVE_FILTER_FR;
            break;
        case Constants_1.LangNames.NL:
            activeFilterName = ACTIVE_FILTER_NL;
            break;
        case Constants_1.LangNames.PT:
            activeFilterName = ACTIVE_FILTER_PT;
            break;
        case Constants_1.LangNames.PL:
            activeFilterName = ACTIVE_FILTER_PL;
            break;
        case Constants_1.LangNames.IT:
            activeFilterName = ACTIVE_FILTER_IT;
            break;
        case Constants_1.LangNames.TR:
            activeFilterName = ACTIVE_FILTER_TR;
            break;
        default:
            activeFilterName = ACTIVE_FILTER_EN;
    }
    return activeFilterName;
};
exports.getActiveFilterTranslate = getActiveFilterTranslate;
