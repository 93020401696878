"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelmetMeta = void 0;
const react_1 = __importStar(require("react"));
const react_helmet_1 = require("react-helmet");
const locales_1 = require("../Constants/locales");
const DESCRIPTION_LENGTH = 155;
const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
const HelmetMeta = ({ initialData, title, description, image, keywords }) => {
    const [metaTagsArray] = (0, react_1.useState)(() => {
        var _a;
        const metaPropertyArray = [];
        metaPropertyArray.push({ property: 'og:site_name', content: 'Haldex' }, { property: 'og:type', content: 'website' });
        if (typeof window !== 'undefined' && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href)) {
            metaPropertyArray.push({ property: 'og:url', content: window.location.href });
        }
        if ((initialData === null || initialData === void 0 ? void 0 : initialData.language) && (locales_1.LOCALES === null || locales_1.LOCALES === void 0 ? void 0 : locales_1.LOCALES[initialData.language])) {
            metaPropertyArray.push({ property: 'og:locale', content: locales_1.LOCALES[initialData.language] });
        }
        if (title) {
            const trimmedTitle = title.trim();
            metaPropertyArray.push({ name: 'title', content: trimmedTitle }, { property: 'og:title', content: trimmedTitle }, { name: 'twitter:title', content: trimmedTitle });
        }
        if (description) {
            const descriptionWithoutTags = description.replace(htmlRegexG, '').trim().substring(0, DESCRIPTION_LENGTH);
            metaPropertyArray.push({ name: 'description', content: descriptionWithoutTags }, { property: 'og:description', content: descriptionWithoutTags }, { name: 'twitter:description', content: descriptionWithoutTags });
        }
        if (image) {
            metaPropertyArray.push({ name: 'twitter:image', content: image }, { name: 'twitter:card', content: 'summary_large_image' }, { property: 'og:image', content: image });
        }
        if (keywords) {
            metaPropertyArray.push({ name: 'keywords', content: keywords.trim() });
        }
        return metaPropertyArray;
    });
    return react_1.default.createElement(react_helmet_1.Helmet, { meta: metaTagsArray });
};
exports.HelmetMeta = HelmetMeta;
