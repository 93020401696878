"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapFooterData = void 0;
const Types_1 = require("Types");
const SocialMediaBlock_1 = require("../Components/UI/Footer/SocialMediaBlock");
const DATE = new Date();
const mapFooterData = (data) => {
    var _a, _b, _c, _d;
    const footerData = {
        columnData: [],
        socialLinks: [],
        copyright: { title: `© Haldex ${DATE.getFullYear()}`, policies: [] },
        linkedSite: {
            whiteLogoUrl: '',
            greyLogoUrl: '',
            text: '',
            link: {
                title: '',
                text: '',
                href: '',
                target: Types_1.Target.BLANK
            }
        }
    };
    if (!data || !data.firstColumnLinks) {
        return null;
    }
    const columnData = [[], [], [], []];
    if (data.firstColumnLinks) {
        (_a = data.firstColumnLinks) === null || _a === void 0 ? void 0 : _a.forEach(l => {
            columnData[0].push({ href: l.href, title: l.title, text: l.text, target: l.target });
        });
    }
    if (data.secondColumnLinks) {
        (_b = data.secondColumnLinks) === null || _b === void 0 ? void 0 : _b.forEach(l => {
            columnData[1].push({ href: l.href, title: l.title, text: l.text, target: l.target });
        });
    }
    if (data.thirdColumnLinks) {
        (_c = data.thirdColumnLinks) === null || _c === void 0 ? void 0 : _c.forEach(l => {
            columnData[2].push({ href: l.href, title: l.title, text: l.text, target: l.target });
        });
    }
    if (data.forthColumnLinks) {
        (_d = data.forthColumnLinks) === null || _d === void 0 ? void 0 : _d.forEach(l => {
            columnData[3].push({ href: l.href, title: l.title, text: l.text, target: l.target });
        });
    }
    footerData.columnData = columnData;
    footerData.socialLinks = [
        {
            link: data.youtube ? `${data.youtube}` : '',
            platform: SocialMediaBlock_1.SocialNetworks.YOUTUBE
        },
        {
            link: data.linkedIn ? `${data.linkedIn}` : '',
            platform: SocialMediaBlock_1.SocialNetworks.LINKEDIN
        },
        {
            link: data.facebook ? `${data.facebook}` : '',
            platform: SocialMediaBlock_1.SocialNetworks.FACEBOOK
        },
        {
            link: data.instagram ? `${data.instagram}` : '',
            platform: SocialMediaBlock_1.SocialNetworks.INSTAGRAM
        },
        {
            link: data.twitter ? `${data.twitter}` : '',
            platform: SocialMediaBlock_1.SocialNetworks.TWITTER
        }
    ];
    if (data.terms) {
        footerData.copyright.policies = data.terms;
    }
    if (data === null || data === void 0 ? void 0 : data.linkedSite) {
        footerData.linkedSite = data === null || data === void 0 ? void 0 : data.linkedSite;
    }
    return footerData;
};
exports.mapFooterData = mapFooterData;
