"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myHaldexVisibility = void 0;
const Types_1 = require("Types");
const myHaldexVisibility = (userData, isNorthAmerica) => {
    let showMyHaldexLinks = true;
    const haveMyHaldexRequiredRole = (userData === null || userData === void 0 ? void 0 : userData.roles) &&
        userData.roles.some(role => role === (isNorthAmerica ? Types_1.UserRoles.NA_DISTRIBUTORS : Types_1.UserRoles.EU_DISTRIBUTORS));
    if (!(userData === null || userData === void 0 ? void 0 : userData.isAuthenticated) || !haveMyHaldexRequiredRole) {
        showMyHaldexLinks = false;
    }
    return showMyHaldexLinks;
};
exports.myHaldexVisibility = myHaldexVisibility;
