"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslate = void 0;
const react_redux_1 = require("react-redux");
const useTranslate = () => {
    const { translations, isReady } = (0, react_redux_1.useSelector)((state) => state.translations);
    const t = key => {
        if (isReady) {
            const label = !!(translations === null || translations === void 0 ? void 0 : translations.length) && translations.find(label => label.key.toLowerCase() === key.toLowerCase());
            return (label === null || label === void 0 ? void 0 : label.value) || key;
        }
    };
    return { t, isReady };
};
exports.useTranslate = useTranslate;
