"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Breadcrumbs = void 0;
const react_1 = __importStar(require("react"));
const styled = __importStar(require("./styled"));
const Types_1 = require("Types");
const styled_bootstrap_grid_1 = require("styled-bootstrap-grid");
const Hooks_1 = require("@/Hooks");
const Theme_1 = require("@/Theme");
const Breadcrumbs = ({ breadcrumbs, keepLayout, showOne, noMargin }) => {
    const { width } = (0, Hooks_1.useWindowSize)();
    const mobileBreadCrumb = (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) > 1 && breadcrumbs[breadcrumbs.length - 2];
    const renderItem = (crumb, idx, length) => {
        const last = idx === length - 1;
        return (react_1.default.createElement(styled.BreadcrumbItemBlock, { key: `breadcrumb-${idx}` }, !last || keepLayout ? (react_1.default.createElement(styled.LinkItem, { href: crumb.href, isFirst: idx === 0 }, crumb.text)) : (react_1.default.createElement(styled.LastLinkItem, null, crumb.text))));
    };
    return (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) > 1 || (showOne && (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) > 0) ? (react_1.default.createElement(styled.Row, null,
        react_1.default.createElement(styled_bootstrap_grid_1.Col, { col: 12 }, width < Theme_1.theme.breakpoints.md && !keepLayout ? (react_1.default.createElement(styled.BreadcrumbBlock, null,
            react_1.default.createElement(styled.LinkItemSolo, { variant: Types_1.LinkAsButtonVariants.RESET_BUTTON, href: mobileBreadCrumb.href },
                react_1.default.createElement(styled.Arrow, null),
                mobileBreadCrumb.text))) : (react_1.default.createElement(styled.BreadcrumbBlock, { noMargin: noMargin }, breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.map((crumb, idx) => renderItem(crumb, idx, breadcrumbs.length))))))) : null;
};
exports.Breadcrumbs = Breadcrumbs;
exports.default = (0, react_1.memo)(exports.Breadcrumbs);
