"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsMegaMenuOpen = exports.menuSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isOpen: false
};
exports.menuSlice = (0, toolkit_1.createSlice)({
    name: 'menuToggle',
    initialState,
    reducers: {
        setIsMegaMenuOpen: (state, action) => {
            state.isOpen = action.payload;
        }
    }
});
exports.setIsMegaMenuOpen = exports.menuSlice.actions.setIsMegaMenuOpen;
exports.default = exports.menuSlice.reducer;
