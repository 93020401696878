"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const serviceCenterLinks_1 = require("../Redux/Actions/serviceCenterLinks");
const useServiceCenterLinks = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const serviceCenterLinksData = (0, react_redux_1.useSelector)((state) => state.serviceCenterLinks.serviceCenterLinks);
    (0, react_1.useEffect)(() => {
        if (serviceCenterLinksData) {
            return;
        }
        dispatch((0, serviceCenterLinks_1.fetchServiceCenterLinks)());
    }, [serviceCenterLinksData]);
    return serviceCenterLinksData;
};
exports.default = useServiceCenterLinks;
