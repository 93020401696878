"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceEncoder = void 0;
const Language_1 = require("./Language");
const languages = ['de', 'es', 'fr'];
const priceEncoder = (price) => {
    const currentLang = (0, Language_1.getLanguage)();
    if (languages.some(lang => lang === currentLang)) {
        const formatterEu = Intl.NumberFormat('de-De', { minimumFractionDigits: 2 });
        return formatterEu.format(price);
    }
    else {
        const formatter = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
        return formatter.format(price);
    }
};
exports.priceEncoder = priceEncoder;
