"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useMouseHover = () => {
    const [isMenuOpen, setIsMenuOpen] = (0, react_1.useState)(false);
    const ref = (0, react_1.useRef)(null);
    const handleMouseOver = () => {
        setIsMenuOpen(true);
    };
    const handleMouseOut = () => setIsMenuOpen(false);
    (0, react_1.useEffect)(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseenter', handleMouseOver);
            node.addEventListener('mouseleave', handleMouseOut);
            return () => {
                node.removeEventListener('mouseenter', handleMouseOver);
                node.removeEventListener('mouseleave', handleMouseOut);
            };
        }
    }, [ref.current]);
    return { ref, isMenuOpen };
};
exports.default = useMouseHover;
