"use strict";
/**
 * ! Remaining updates every second do not use this hook in components with requests or heavy calculations
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpressOrders = void 0;
const react_1 = require("react");
const Utils_1 = require("../Utils");
const cart_1 = require("../Endpoints/cart");
const useCurrentPage_1 = require("./useCurrentPage");
const siteSettings_1 = require("../Redux/Actions/siteSettings");
const react_redux_1 = require("react-redux");
const useExpressOrders = () => {
    const [endDateTime, setEndDateTime] = (0, react_1.useState)();
    const [remaining, setRemaining] = (0, react_1.useState)();
    const [firstRender, setFirstRender] = (0, react_1.useState)(false);
    const page = (0, useCurrentPage_1.useCurrentPage)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const isWeekend = (date = new Date()) => {
        return date.getDay() === 6 || date.getDay() === 0;
    };
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = page === null || page === void 0 ? void 0 : page.contentLink) === null || _a === void 0 ? void 0 : _a.id) && !firstRender) {
            (0, cart_1.getExpressOrdersDaysOff)(page.contentLink.id).then(res => {
                var _a, _b, _c, _d, _e;
                const today = {
                    formattedDate: (0, Utils_1.dateFormatConverter)(new Date()),
                    time: new Date().toTimeString().split(' ')[0]
                };
                const formattedDaysOff = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.daysOff) === null || _b === void 0 ? void 0 : _b.map(day => (0, Utils_1.dateFormatConverter)(new Date(day)));
                const formattedEndDateTime = {
                    formattedDate: (0, Utils_1.dateFormatConverter)(new Date((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.endDateTime)),
                    time: new Date((_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.endDateTime).toTimeString().split(' ')[0]
                };
                setEndDateTime(new Date((_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.endDateTime));
                const invalidDate = (formattedDaysOff && formattedDaysOff.some(date => date === formattedEndDateTime.formattedDate)) ||
                    isWeekend();
                const invalidTime = today.formattedDate === formattedEndDateTime.formattedDate && today.time >= formattedEndDateTime.time;
                setFirstRender(true);
                dispatch((0, siteSettings_1.setExpressAvailable)(!invalidDate && !invalidTime));
                dispatch((0, siteSettings_1.setExpressOrdersDaysOff)(formattedDaysOff));
            });
        }
    }, [page]);
    (0, react_1.useEffect)(() => {
        const intervalId = setInterval(() => {
            if (endDateTime) {
                const result = (0, Utils_1.remainingTime)(endDateTime);
                setRemaining(result);
                result.isDisabled && dispatch((0, siteSettings_1.setExpressAvailable)(false));
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [endDateTime]);
    return {
        endDateTime,
        remaining
    };
};
exports.useExpressOrders = useExpressOrders;
exports.default = exports.useExpressOrders;
