"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadFile = void 0;
const downloadFile = (data, fileName) => {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = fileName;
    a.click();
};
exports.downloadFile = downloadFile;
