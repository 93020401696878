"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addSpaces = void 0;
const addSpaces = (str) => {
    const strArr = str.split('');
    for (let i = 1; i < strArr.length; i++) {
        const char = strArr[i];
        if (char === char.toUpperCase()) {
            strArr.splice(i, 0, ' ');
            i++;
        }
    }
    return strArr.join('');
};
exports.addSpaces = addSpaces;
