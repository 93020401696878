"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDevice = void 0;
const react_1 = require("react");
const Theme_1 = require("../Theme");
const createDevicesStatuses = () => ({
    isXs: window.innerWidth <= Theme_1.theme.breakpoints.xs,
    isMobile: window.innerWidth <= Theme_1.theme.breakpoints.sm,
    isTablet: window.innerWidth <= Theme_1.theme.breakpoints.md && window.innerWidth > Theme_1.theme.breakpoints.sm,
    isTabletAndMobile: window.innerWidth <= Theme_1.theme.breakpoints.md,
    isDesktopSmall: window.innerWidth <= Theme_1.theme.breakpoints.lg,
    isDesktopSmall2: window.innerWidth < Theme_1.theme.breakpoints.lg,
    isDesktopSmall3: window.innerWidth <= Theme_1.theme.breakpoints.xl,
    isDesktopSmall4: window.innerWidth < Theme_1.theme.breakpoints.xxl,
    isDesktop: window.innerWidth > Theme_1.theme.breakpoints.md,
    isDesktopMedium: window.innerWidth > Theme_1.theme.breakpoints.lg && window.innerWidth <= Theme_1.theme.breakpoints.xxl,
    isDesktopLarge: window.innerWidth > Theme_1.theme.breakpoints.xxl && window.innerWidth <= Theme_1.theme.breakpoints.xxxl,
    isDesktopXLarge: window.innerWidth > Theme_1.theme.breakpoints.xxxl
});
const useDevice = () => {
    const [devices, setDevices] = (0, react_1.useState)(() => createDevicesStatuses());
    (0, react_1.useEffect)(() => {
        function handleResize() {
            setDevices(createDevicesStatuses());
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return devices;
};
exports.useDevice = useDevice;
