"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled = __importStar(require("./styled"));
const Typography_1 = require("../../../UI/Typography");
const RegionSelectorButton = ({ isRegional, openRegionSelector, transparent, activeRegion, isDesktopXLarge }) => {
    return (react_1.default.createElement(styled.RegionContainer, { onClick: openRegionSelector },
        react_1.default.createElement(styled.RegionIcon, { "$isTransparent": transparent }),
        react_1.default.createElement(styled.RegionTitle, { type: Typography_1.Types.EYEBROW_SMALL, "$isTransparent": transparent }, !isRegional && !isDesktopXLarge ? '' : activeRegion)));
};
exports.default = (0, react_1.memo)(RegionSelectorButton);
