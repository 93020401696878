"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariantTypes = exports.UnitVariants = void 0;
var UnitVariants;
(function (UnitVariants) {
    UnitVariants["EACH"] = "EA";
    UnitVariants["FT"] = "FT";
})(UnitVariants = exports.UnitVariants || (exports.UnitVariants = {}));
var VariantTypes;
(function (VariantTypes) {
    VariantTypes["MINI_CART_VIEW"] = "miniCartView";
    VariantTypes["CART_VIEW"] = "cartView";
    VariantTypes["FULL_VIEW"] = "fullView";
    VariantTypes["CAROUSEL_VIEW"] = "carouselView";
    VariantTypes["SEARCH_VIEW"] = "searchView";
    VariantTypes["PRODUCT_VIEW"] = "productView";
    VariantTypes["ORDER_CONFIRMATION"] = "orderConfirmationView";
})(VariantTypes = exports.VariantTypes || (exports.VariantTypes = {}));
