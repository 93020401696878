"use strict";
/**
 * Legend:
 * T - transparency
 * L - lighter
 * D - darker
 * BW - black and white
 * GR - linear gradient
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.palette = void 0;
exports.palette = {
    // ====================================================
    // Black transparent
    // ====================================================
    BW_0_2T: 'rgba(0, 0, 0, 0.02)',
    BW_0_3T: 'rgba(0, 0, 0, 0.03)',
    BW_0_5T: 'rgba(0, 0, 0, 0.05)',
    BW_0_7T: 'rgba(0, 0, 0, 0.07)',
    BW_0_10T: 'rgba(0, 0, 0, 0.10)',
    BW_0_13T: 'rgba(0, 0, 0, 0.13)',
    BW_0_15T: 'rgba(0, 0, 0, 0.15)',
    BW_0_17T: 'rgba(0, 0, 0, 0.17)',
    BW_0_20T: 'rgba(0, 0, 0, 0.20)',
    BW_0_25T: 'rgba(0, 0, 0, 0.25)',
    BW_0_30T: 'rgba(0, 0, 0, 0.30)',
    BW_0_35T: 'rgba(0, 0, 0, 0.35)',
    BW_0_40T: 'rgba(0, 0, 0, 0.40)',
    BW_0_45T: 'rgba(0, 0, 0, 0.45)',
    BW_0_50T: 'rgba(0, 0, 0, 0.50)',
    BW_0_55T: 'rgba(0, 0, 0, 0.55)',
    BW_0_60T: 'rgba(0, 0, 0, 0.60)',
    BW_0_65T: 'rgba(0, 0, 0, 0.65)',
    BW_0_70T: 'rgba(0, 0, 0, 0.70)',
    BW_0_75T: 'rgba(0, 0, 0, 0.75)',
    BW_0_80T: 'rgba(0, 0, 0, 0.80)',
    BW_0_85T: 'rgba(0, 0, 0, 0.85)',
    BW_0_90T: 'rgba(0, 0, 0, 0.90)',
    BW_0_95T: 'rgba(0, 0, 0, 0.95)',
    // ====================================================
    // White transparent
    // ====================================================
    BW_100_2T: 'rgba(255, 255, 255, 0.02)',
    BW_100_3T: 'rgba(255, 255, 255, 0.03)',
    BW_100_5T: 'rgba(255, 255, 255, 0.05)',
    BW_100_8T: 'rgba(255, 255, 255, 0.08)',
    BW_100_10T: 'rgba(255, 255, 255, 0.10)',
    BW_100_13T: 'rgba(255, 255, 255, 0.13)',
    BW_100_15T: 'rgba(255, 255, 255, 0.15)',
    BW_100_17T: 'rgba(255, 255, 255, 0.17)',
    BW_100_20T: 'rgba(255, 255, 255, 0.20)',
    BW_100_25T: 'rgba(255, 255, 255, 0.25)',
    BW_100_30T: 'rgba(255, 255, 255, 0.30)',
    BW_100_35T: 'rgba(255, 255, 255, 0.35)',
    BW_100_40T: 'rgba(255, 255, 255, 0.40)',
    BW_100_45T: 'rgba(255, 255, 255, 0.45)',
    BW_100_50T: 'rgba(255, 255, 255, 0.50)',
    BW_100_55T: 'rgba(255, 255, 255, 0.55)',
    BW_100_60T: 'rgba(255, 255, 255, 0.60)',
    BW_100_65T: 'rgba(255, 255, 255, 0.65)',
    BW_100_70T: 'rgba(255, 255, 255, 0.70)',
    BW_100_75T: 'rgba(255, 255, 255, 0.75)',
    BW_100_80T: 'rgba(255, 255, 255, 0.80)',
    BW_100_85T: 'rgba(255, 255, 255, 0.85)',
    BW_100_90T: 'rgba(255, 255, 255, 0.90)',
    BW_100_95T: 'rgba(255, 255, 255, 0.95)',
    // ====================================================
    // Grey transparent
    // ====================================================,
    GREY_100_03T: 'rgba(211, 211, 211, 0.3)',
    GREY_100_05T: 'rgba(240, 240, 240, 0.5)',
    // ====================================================
    // Black/white
    // ====================================================
    BW_0: '#000000',
    BW_5: '#0D0D0D',
    BW_8: '#141414',
    BW_10: '#1A1A1A',
    BW_13: '#212121',
    BW_15: '#262626',
    BW_17: '#2B2B2B',
    BW_20: '#333333',
    BW_25: '#404040',
    BW_30: '#4D4D4D',
    BW_35: '#595959',
    BW_40: '#666666',
    BW_45: '#737373',
    BW_50: '#808080',
    BW_55: '#8C8C8C',
    BW_60: '#999999',
    BW_65: '#A6A6A6',
    BW_70: '#B3B3B3',
    BW_75: '#BFBFBF',
    BW_80: '#CCCCCC',
    BW_85: '#D9D9D9',
    BW_90: '#E6E6E6',
    BW_95: '#F2F2F2',
    BW_100: '#FFFFFF',
    // ====================================================
    // Haldex Blue
    // ====================================================
    BLUE_100: '#0060A7',
    BLUE_90: '#0061A6',
    BLUE_80: '#3380B9',
    BLUE_60: '#66A0CA',
    BLUE_50: '#41637C',
    BLUE_40: '#99BFDC',
    BLUE_20: '#CCDFED',
    BLUE_10: '#F4F8FB',
    BLUE_5: '#FBFBFB',
    // ====================================================
    // Medium gray
    // ====================================================
    MEDIUM_GRAY_100: '#878D92',
    MEDIUM_GRAY_90: '#f8f8f8',
    MEDIUM_GRAY: '#C1C1C1',
    // ====================================================
    // Dark gray
    // ====================================================
    DARK_GRAY_100: '#373D3E',
    DARK_GRAY_80: '#5F6364',
    DARK_GRAY_60: '#878A8B',
    DARK_GRAY_40: '#AFB1B1',
    DARK_GRAY_20: '#D7D8D8',
    DARK_GRAY: '#575757',
    // ====================================================
    // Dark blue
    // ====================================================
    DARK_BLUE_100: '#00355A',
    DARK_BLUE_2T: '#00355ab3',
    // ====================================================
    // Blue gray
    // ====================================================
    BLUE_GRAY_100: '#465D6B',
    BLUE_GRAY_80: '#6B7D89',
    BLUE_GRAY_60: '#909EA6',
    BLUE_GRAY_40: '#B5BEC4',
    BLUE_GRAY_20: '#DADFE1',
    // ====================================================
    // Blue green
    // ====================================================
    BLUE_GREEN_100: '#006C5F',
    BLUE_GREEN_80: '#33897F',
    BLUE_GREEN_60: '#66A79F',
    BLUE_GREEN_40: '#99C4BF',
    BLUE_GREEN_20: '#CCE2DF',
    // ====================================================
    // Green
    // ====================================================
    GREEN_100: '#466D3C',
    GREEN_80: '#9DD190',
    GREEN_10: '#F1FFED',
    // ====================================================
    // Purple
    // ====================================================
    PURPLE_100: '#5B3071',
    PURPLE_80: '#7C598D',
    PURPLE_60: '#9D82A9',
    PURPLE_40: '#BDACC6',
    PURPLE_20: '#DED5E2',
    // ====================================================
    // Red
    // ====================================================
    RED_100: '#D00129',
    RED_80: '#D93454',
    RED_60: '#E3677F',
    RED_40: '#EC99A9',
    RED_20: '#F6CCD4',
    RED_10: '#FFFAFA',
    // ====================================================
    // Orange
    // ====================================================
    ORANGE_100: '#F06C0D',
    ORANGE_80: '#F2893D',
    ORANGE_60: '#F5A76E',
    ORANGE_40: '#F9C49E',
    ORANGE_20: '#FCE2CF',
    // ====================================================
    // Yellow
    // ====================================================
    YELLOW_100: '#FFD100',
    YELLOW_90: '#9B653F',
    YELLOW_80: '#FFDA33',
    YELLOW_60: '#FFE366',
    YELLOW_40: '#FFED99',
    YELLOW_20: '#FFF6CC',
    YELLOW_10: '#FEF9EB',
    // ====================================================
    // Light Gray
    // ====================================================
    LIGHT_GRAY_100: '#B3B3B3',
    LIGHT_GRAY_90: ' #B5BEC4',
    LIGHT_GRAY_80: '#C2C2C2',
    LIGHT_GRAY_60: '#D1D1D1',
    LIGHT_GRAY_40: '#E1E1E1',
    LIGHT_GRAY_30: '#E5EBEE',
    LIGHT_GRAY_20: '#F0F0F0',
    LIGHT_GRAY_10: '#F7F7F7',
    LIGHT_GRAY_5: '#FAFAFB',
    LIGHT_GRAY: '#F1F1F1',
    // ====================================================
    // Light Black
    // ====================================================
    LIGHT_BLACK: '#061C2B',
    LIGHT_BLACK_ENCODED: '%23061C2B',
    // ====================================================
    // Agri Green
    // ====================================================
    AGRI_GREEN_100: '#00A138',
    AGRI_GREEN_80: '#75A968',
    // ====================================================
    // UI colors
    // ====================================================
    UI_BORDER_DARK: '#233340',
    UI_DISABLED_BORDER: '#B3B3B3',
    UI_DISABLED_DETAIL: '#BBCAD3',
    UI_DISABLED_BACKGROUND: '#E5EBEE',
    UI_FOCUS_TAB: '#F06C0D',
    UI_WARNING: '#D00129',
    // ====================================================
    // Font colors
    // ====================================================
    TYPE_LIGHT_BLACK: '#061C2B',
    SVART: '#28261E',
    // ====================================================
    // Gradients
    // ====================================================
    GR_BLUE: 'linear-gradient(90.9deg, rgba(127, 182, 237, 0.36) 2.23%, rgba(0, 97, 166, 0) 50.77%), #456483',
    GR_GRAY: 'linear-gradient(to top, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 80%)',
    DESCRIPTION_OVERLAY: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 59.61%)',
    GR_WHITE: `linear-gradient(180deg, 'rgba(255, 255, 255, 0.02)' 0%, #ffffff 80%)`,
    // ====================================================
    // Logo colors
    // ====================================================
    HALDEX_BLUE: '#0060A6',
    LIKE_NU_GREEN: '#638C1C',
    GRAU_ORANGE: '#EF7700',
    MIDLAND_RED: '#D2232A',
    REMAN_BROWN: '#8C5E29',
    // ====================================================
    // Icon colors
    // ====================================================
    WARNING_YELLOW: '#FFFF00',
    WARNING_BLACK: '#000000'
};
