"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateAccountDropdownsData = void 0;
const generateAccountDropdownsData = (shipTos) => {
    return shipTos.map((item, index) => {
        return {
            name: `${(item === null || item === void 0 ? void 0 : item.name) || ''} ${(item === null || item === void 0 ? void 0 : item.city) || ''} - ${(item === null || item === void 0 ? void 0 : item.customerId) || ''}`,
            id: index.toString()
        };
    });
};
exports.generateAccountDropdownsData = generateAccountDropdownsData;
