"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHeaderData = void 0;
const Types_1 = require("Types");
const mapHeaderData = (headerData) => {
    const header = {
        menuData: [],
        settings: {
            logo: '',
            regions: [],
            linkedSite: {
                whiteLogoUrl: '',
                greyLogoUrl: '',
                text: '',
                link: { href: '' }
            }
        }
    };
    if (!headerData || !headerData.menuItems) {
        return null;
    }
    const menuData = [];
    headerData.menuItems.forEach(firstItem => {
        var _a;
        const secondLevel = [];
        firstItem.children.forEach(secondItem => {
            const thirdLevel = [];
            secondItem.children.forEach(thirdItem => {
                thirdLevel.push({
                    title: thirdItem.title,
                    uri: thirdItem.uri,
                    target: (thirdItem === null || thirdItem === void 0 ? void 0 : thirdItem.target) ? thirdItem.target : Types_1.Target.SELF
                });
            });
            secondLevel.push({
                title: secondItem.title,
                uri: secondItem.uri,
                thirdLevelItems: thirdLevel,
                target: (secondItem === null || secondItem === void 0 ? void 0 : secondItem.target) ? secondItem.target : Types_1.Target.SELF
            });
        });
        menuData.push({
            promoRef: ((_a = firstItem.promoRef) === null || _a === void 0 ? void 0 : _a.id)
                ? {
                    id: firstItem.promoRef.id
                }
                : null,
            title: firstItem.title,
            uri: firstItem.uri,
            secondLevelItems: secondLevel
        });
    });
    header.menuData = menuData;
    header.settings = headerData.globalSettings;
    header.currentRegion = headerData.region;
    header.currentLanguage = headerData.language;
    header.hasCart = headerData.hasCart;
    header.cartPage = headerData.cartPage;
    header.LogoUrl = headerData.logoUrl;
    return header;
};
exports.mapHeaderData = mapHeaderData;
