"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compareStringArrays = void 0;
const compareStringArrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
};
exports.compareStringArrays = compareStringArrays;
