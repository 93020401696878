"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentPage = void 0;
const react_1 = require("react");
const spa_core_1 = require("@episerver/spa-core");
const useCurrentPage = () => {
    var _a;
    const [page, setPage] = (0, react_1.useState)();
    const state = (0, spa_core_1.useEpiserver)().getStore().getState();
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (!((_a = state === null || state === void 0 ? void 0 : state.OptiContentCloud) === null || _a === void 0 ? void 0 : _a.iContent))
            return;
        setPage((_b = state === null || state === void 0 ? void 0 : state.OptiContentCloud) === null || _b === void 0 ? void 0 : _b.iContent);
    }, [(_a = state === null || state === void 0 ? void 0 : state.OptiContentCloud) === null || _a === void 0 ? void 0 : _a.iContent, setPage]);
    return page;
};
exports.useCurrentPage = useCurrentPage;
