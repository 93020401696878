"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFooterData = void 0;
const react_1 = require("react");
const useCurrentPage_1 = require("./useCurrentPage");
const react_redux_1 = require("react-redux");
const footer_1 = require("../Redux/Actions/footer");
const useFooterData = () => {
    const page = (0, useCurrentPage_1.useCurrentPage)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const footerData = (0, react_redux_1.useSelector)((state) => state.footer.footer);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (footerData) {
            return;
        }
        if (page) {
            dispatch((0, footer_1.fetchFooter)((_b = (_a = page.contentLink) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()));
        }
    }, [page, footerData]);
    return footerData;
};
exports.useFooterData = useFooterData;
exports.default = exports.useFooterData;
