"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradientLevels = void 0;
var GradientLevels;
(function (GradientLevels) {
    GradientLevels["ZERO"] = "0";
    GradientLevels["LOW"] = "20";
    GradientLevels["MEDIUM"] = "40";
    GradientLevels["HIGH"] = "70";
    GradientLevels["MAX"] = "100";
})(GradientLevels = exports.GradientLevels || (exports.GradientLevels = {}));
