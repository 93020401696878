"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsFavoriteShiptosModalOpen = exports.favoriteShiptosModalSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isFavoriteShiptosModalOpen: false
};
exports.favoriteShiptosModalSlice = (0, toolkit_1.createSlice)({
    name: 'favoriteShiptosModalToggle',
    initialState,
    reducers: {
        setIsFavoriteShiptosModalOpen: (state, action) => {
            state.isFavoriteShiptosModalOpen = action.payload;
        }
    }
});
exports.setIsFavoriteShiptosModalOpen = exports.favoriteShiptosModalSlice.actions.setIsFavoriteShiptosModalOpen;
exports.default = exports.favoriteShiptosModalSlice.reducer;
