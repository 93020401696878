"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDiscountData = void 0;
const priceEncoder_1 = require("./priceEncoder");
const getDiscountData = (t, prices, unitDecode) => {
    const discountData = [];
    for (let index = 1; index < prices.length; index++) {
        discountData.push({
            info: prices[index].itemPrice === 0
                ? t('/haldex/add_to_cart/no_price')
                : `${prices[index].currencySymbol}${(0, priceEncoder_1.priceEncoder)(prices[index].itemPrice)} / ${unitDecode(prices[index].unitId)}`,
            price: index === prices.length - 1
                ? `${prices[index].quantity}+`
                : `${prices[index].quantity}-${parseInt(prices[index + 1].quantity) - 1}`
        });
    }
    return discountData;
};
exports.getDiscountData = getDiscountData;
