"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVariationItemContentData = void 0;
const react_1 = require("react");
const react_router_1 = require("react-router");
const nodes_1 = require("@/Endpoints/nodes");
const Utils_1 = require("@/Utils");
const Constants_1 = require("@/Constants");
const Hooks_1 = require("@/Hooks");
const useVariationItemContentData = () => {
    var _a;
    const location = (0, react_router_1.useLocation)();
    const initialData = (0, Hooks_1.useInitialData)();
    const language = (0, Utils_1.getLanguage)();
    const continent = (_a = initialData === null || initialData === void 0 ? void 0 : initialData.continent) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    const isEurope = Constants_1.EUROPE.includes(continent);
    const cookieCatalogueLanguage = (0, Utils_1.getCookie)(Constants_1.Cookies.CATALOG_LANGUAGE_COOKIE_NAME);
    const languageParam = cookieCatalogueLanguage && isEurope ? JSON.parse(cookieCatalogueLanguage).code : language;
    const [data, setData] = (0, react_1.useState)({});
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        if (!continent)
            return;
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield (0, nodes_1.getNodeData)((0, Utils_1.changeLanguageLocaleInUrl)(location.pathname, languageParam));
            setData(result);
            setIsLoading(false);
        });
        if (!isEurope) {
            setIsLoading(false);
        }
        else {
            fetchData();
        }
    }, [isEurope, location, continent]);
    const { breadcrumbs, variationModel, relatedVariants, crossReferences } = data;
    return {
        variationModel,
        breadcrumbs,
        relatedVariants,
        crossReferences,
        isLoading
    };
};
exports.useVariationItemContentData = useVariationItemContentData;
