"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setServiceCenterLinksData = exports.serviceCenterLinksSlice = exports.fetchServiceCenterLinks = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const app_1 = require("../../Endpoints/app");
const initialState = {
    serviceCenterLinks: null
};
exports.fetchServiceCenterLinks = (0, toolkit_1.createAsyncThunk)('fetchServiceCenterLinks', () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, app_1.getServiceCenterLinks)();
    return response;
}));
exports.serviceCenterLinksSlice = (0, toolkit_1.createSlice)({
    name: 'serviceCenterLinksSlice',
    initialState,
    extraReducers: builder => {
        builder.addCase(exports.fetchServiceCenterLinks.fulfilled, (state, action) => {
            state.serviceCenterLinks = action.payload;
        });
    },
    reducers: {
        setServiceCenterLinksData: (state, action) => {
            state.serviceCenterLinks = action.payload;
        }
    }
});
exports.setServiceCenterLinksData = exports.serviceCenterLinksSlice.actions.setServiceCenterLinksData;
exports.default = exports.serviceCenterLinksSlice.reducer;
