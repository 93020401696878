"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingPageTypes = void 0;
var ListingPageTypes;
(function (ListingPageTypes) {
    ListingPageTypes[ListingPageTypes["PRESS_RELEASE"] = 1] = "PRESS_RELEASE";
    ListingPageTypes[ListingPageTypes["REPORTS_PRESENTAIONS"] = 2] = "REPORTS_PRESENTAIONS";
    ListingPageTypes[ListingPageTypes["CALENDAR"] = 3] = "CALENDAR";
    ListingPageTypes[ListingPageTypes["GALLERY"] = 4] = "GALLERY";
    ListingPageTypes[ListingPageTypes["JOB_LISTING"] = 5] = "JOB_LISTING";
    ListingPageTypes[ListingPageTypes["NEWS_ARTICLE"] = 6] = "NEWS_ARTICLE";
})(ListingPageTypes = exports.ListingPageTypes || (exports.ListingPageTypes = {}));
