"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoles = exports.EContinentKeys = exports.ShippingTypes = void 0;
var ShippingTypes;
(function (ShippingTypes) {
    ShippingTypes[ShippingTypes["Standard"] = 1] = "Standard";
    ShippingTypes[ShippingTypes["Rush"] = 2] = "Rush";
    ShippingTypes[ShippingTypes["AdvantageFreight"] = 3] = "AdvantageFreight";
    ShippingTypes[ShippingTypes["CustomerPickUp"] = 4] = "CustomerPickUp";
    ShippingTypes[ShippingTypes["StockOrders"] = 5] = "StockOrders";
    ShippingTypes[ShippingTypes["ExpressOrders"] = 6] = "ExpressOrders";
    ShippingTypes[ShippingTypes["CustomerCollect"] = 7] = "CustomerCollect";
})(ShippingTypes = exports.ShippingTypes || (exports.ShippingTypes = {}));
var EContinentKeys;
(function (EContinentKeys) {
    EContinentKeys[EContinentKeys["Undefined"] = 0] = "Undefined";
    EContinentKeys[EContinentKeys["Europe"] = 1] = "Europe";
    EContinentKeys[EContinentKeys["NorthAmerica"] = 2] = "NorthAmerica";
    EContinentKeys[EContinentKeys["Canada"] = 3] = "Canada";
})(EContinentKeys = exports.EContinentKeys || (exports.EContinentKeys = {}));
var UserRoles;
(function (UserRoles) {
    UserRoles["NA_DISTRIBUTORS"] = "NorthAmerica_Distributors";
    UserRoles["NA_SEE_INVOICES"] = "NorthAmerica_SeeInvoices";
    UserRoles["NA_REGISTERED"] = "NorthAmerica_Registered";
    UserRoles["NA_CORE_REPORTS"] = "NorthAmerica_CoreReports";
    UserRoles["ADMINISTRATORS"] = "Administrators";
    UserRoles["NA_PLACE_ORDERS"] = "NorthAmerica_PlaceOrders";
    UserRoles["NA_CAN_SEE_PRICING"] = "NorthAmerica_CanSeePricing";
    UserRoles["EU_REGISTERED"] = "Europe_Registered";
    UserRoles["EU_DISTRIBUTORS"] = "Europe_Distributors";
    UserRoles["EU_ALLOW_DROP_SHIP"] = "Europe_AllowDropShip";
    UserRoles["EU_CAN_SEE_PRICING"] = "Europe_CanSeePricing";
    UserRoles["EU_SEE_INVOICES"] = "Europe_SeeInvoices";
    UserRoles["EU_PLACE_ORDERS"] = "Europe_PlaceOrders";
    UserRoles["EU_SEE_LIST_PRICING"] = "Europe_See_List_Prices";
    UserRoles["EU_EXTRA_FEE"] = "Europe_ExtraFee";
    UserRoles["ADVANCED_CROSS_REFERENCE_SEARCH"] = "AdvancedCrossReferenceSearch";
    UserRoles["NA_EXTENDED_CORE_REPORTS"] = "NorthAmerica_CoreReportExtended";
})(UserRoles = exports.UserRoles || (exports.UserRoles = {}));
var IErrorCode;
(function (IErrorCode) {
    IErrorCode[IErrorCode["UserLockedOut"] = 1] = "UserLockedOut";
    IErrorCode[IErrorCode["UserNotActive"] = 2] = "UserNotActive";
    IErrorCode[IErrorCode["UserMissingShipToAddresses"] = 3] = "UserMissingShipToAddresses";
    IErrorCode[IErrorCode["UserInvalidCredentials"] = 4] = "UserInvalidCredentials";
    IErrorCode[IErrorCode["NorthAmericaUserNotActive"] = 7] = "NorthAmericaUserNotActive";
})(IErrorCode || (IErrorCode = {}));
