"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookie = exports.deleteCookie = exports.setCookie = void 0;
const setCookie = (name, val, expireInDays = 7) => {
    const date = new Date();
    const value = val;
    // Set it expire in default 7 days
    date.setTime(date.getTime() + expireInDays * 24 * 60 * 60 * 1000);
    // Set it
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
};
exports.setCookie = setCookie;
const deleteCookie = (name) => {
    const date = new Date();
    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    // Set it
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
};
exports.deleteCookie = deleteCookie;
const getCookie = (name) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
};
exports.getCookie = getCookie;
exports.default = exports.getCookie;
