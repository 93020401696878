"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterDate = exports.dateFormatConverter = exports.getNextMonday = exports.startShippingDate = void 0;
const SATURDAY = 6;
const SUNDAY = 0;
const startShippingDate = (workingDays) => {
    const currentDate = new Date();
    while (workingDays > 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            workingDays--;
        }
    }
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
};
exports.startShippingDate = startShippingDate;
const getNextMonday = () => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const nextMonday = new Date(today.setDate(today.getDate() + ((7 - today.getDay() + 1) % 7 || 7)));
    return nextMonday;
};
exports.getNextMonday = getNextMonday;
const dateFormatConverter = (date) => {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    return year + '/' + month + '/' + day;
};
exports.dateFormatConverter = dateFormatConverter;
const filterDate = (date, nearestAvailableDate) => {
    const day = date.getDay();
    return day !== SUNDAY && day !== SATURDAY && date >= nearestAvailableDate;
};
exports.filterDate = filterDate;
