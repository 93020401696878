"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const SelectDropdown_1 = require("@/Components/UI/SelectDropdown/SelectDropdown");
const Constants_1 = require("@/Constants");
const Utils_1 = require("@/Utils");
const Hooks_1 = require("@/Hooks");
const ProductCatalogLanguageSelector = ({ Wrapper, className }) => {
    const { language, continent } = (0, Hooks_1.useInitialData)();
    const siteLanguage = language || Constants_1.DEFAULT_SITE_LOCALE;
    const isEurope = Constants_1.EUROPE.includes(continent === null || continent === void 0 ? void 0 : continent.toLowerCase());
    const cookieCatalogueLanguage = (0, Utils_1.getCookie)(Constants_1.Cookies.CATALOG_LANGUAGE_COOKIE_NAME);
    const hasWrapper = !!Wrapper && !!(Wrapper === null || Wrapper === void 0 ? void 0 : Wrapper.styledComponentId);
    const [catalogLanguage, setCatalogLanguage] = (0, react_1.useState)(cookieCatalogueLanguage ? JSON.parse(cookieCatalogueLanguage) : {});
    (0, react_1.useEffect)(() => {
        !(catalogLanguage === null || catalogLanguage === void 0 ? void 0 : catalogLanguage.code) &&
            setCatalogLanguage(Constants_1.CATALOG_LANGUAGES_DROPDOWN_VALUES === null || Constants_1.CATALOG_LANGUAGES_DROPDOWN_VALUES === void 0 ? void 0 : Constants_1.CATALOG_LANGUAGES_DROPDOWN_VALUES.find(item => item.code === siteLanguage));
    }, [siteLanguage, catalogLanguage === null || catalogLanguage === void 0 ? void 0 : catalogLanguage.code]);
    const onCatalogLanguageValueChange = (value) => {
        setCatalogLanguage(value);
        value && (0, Utils_1.setCookie)(Constants_1.Cookies.CATALOG_LANGUAGE_COOKIE_NAME, JSON.stringify(value), 365);
        window.location.reload();
    };
    if (!isEurope)
        return null;
    if (hasWrapper)
        return (react_1.default.createElement(Wrapper, null,
            react_1.default.createElement(SelectDropdown_1.SelectDropdown, { options: Constants_1.CATALOG_LANGUAGES_DROPDOWN_VALUES, value: catalogLanguage, setValue: onCatalogLanguageValueChange, className: className })));
    return (react_1.default.createElement(SelectDropdown_1.SelectDropdown, { options: Constants_1.CATALOG_LANGUAGES_DROPDOWN_VALUES, value: catalogLanguage, setValue: onCatalogLanguageValueChange, className: className }));
};
exports.default = ProductCatalogLanguageSelector;
