"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setQuickOrderMessages = exports.setCartData = exports.setIsOpen = exports.CartSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const Types_1 = require("Types");
const initialState = {
    isOpen: false,
    cartData: {
        showShippingDateControls: false,
        numberOfItems: 0,
        totalPrice: {
            amount: 0,
            currencyCode: Types_1.CurrencyCodeEnum.USD
        },
        totalPriceForItems: {
            amount: 0,
            currencyCode: Types_1.CurrencyCodeEnum.USD
        },
        lineItems: [],
        summary: {
            totalItemsPrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            },
            shippingTotalPrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            },
            totalVatPrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            },
            totalPrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            },
            feePrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            },
            feeLimitPrice: {
                amount: 0,
                currencyCode: Types_1.CurrencyCodeEnum.USD
            }
        },
        frozenPeriodOfWorkingDays: 0
    },
    quickOrderMessages: {
        errors: [],
        notifications: []
    }
};
exports.CartSlice = (0, toolkit_1.createSlice)({
    name: 'cart',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setCartData: (state, action) => {
            state.cartData = action.payload;
        },
        setQuickOrderMessages: (state, action) => {
            state.quickOrderMessages = action.payload;
        }
    }
});
_a = exports.CartSlice.actions, exports.setIsOpen = _a.setIsOpen, exports.setCartData = _a.setCartData, exports.setQuickOrderMessages = _a.setQuickOrderMessages;
exports.default = exports.CartSlice.reducer;
