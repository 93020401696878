"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToCamelCase = void 0;
const objectToCamelCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === 'object') {
                value = (0, exports.objectToCamelCase)(value);
            }
            return value;
        });
    }
    else {
        newO = {};
        for (origKey in o) {
            // eslint-disable-next-line no-prototype-builtins
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey];
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    value = (0, exports.objectToCamelCase)(value);
                }
                // @ts-ignore
                newO[newKey] = value;
            }
        }
    }
    return newO;
};
exports.objectToCamelCase = objectToCamelCase;
