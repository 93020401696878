"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDefineTaxText = void 0;
const react_redux_1 = require("react-redux");
const Constants_1 = require("@/Constants");
const useTranslate_1 = require("@/Hooks/useTranslate");
const useDefineTaxText = () => {
    const { t } = (0, useTranslate_1.useTranslate)();
    const continent = (0, react_redux_1.useSelector)((state) => state.initialData.continent);
    const isNorthAmerica = Constants_1.NORTH_AMERICA.includes(continent === null || continent === void 0 ? void 0 : continent.toLowerCase());
    return isNorthAmerica ? t('/haldex/cart_page/total_info/sales_tax') : t('/haldex/cart_page/total_info/vat');
};
exports.useDefineTaxText = useDefineTaxText;
