"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiKeys = exports.getErrorPagesData = exports.getInitData = exports.getServiceCenterLinks = exports.getFooterData = exports.getHeaderData = exports.getCatalogTranslationsData = exports.getTranslationsData = void 0;
const request_1 = require("./request");
const Constants_1 = require("@/Constants");
const Utils_1 = require("@/Utils");
const getTranslationsData = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield (0, request_1.request)(`${Constants_1.AppEndpoints.TRANSLATIONS}`, request_1.RequestType.POST, null, null, true);
    }
    catch (error) {
        // Delete cookie if unable to fetch translations so we re-fetch it next render.
        (0, Utils_1.deleteCookie)(Constants_1.Cookies.TRANSLATIONS_REFETCH_TIME);
        throw new Error(`Could not get translation data, received the following error: ${error}`);
    }
});
exports.getTranslationsData = getTranslationsData;
const getCatalogTranslationsData = (language) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield (0, request_1.request)(`${Constants_1.AppEndpoints.TRANSLATIONS}`, request_1.RequestType.POST, null, null, true, null, language);
    }
    catch (error) {
        // Delete cookie if unable to fetch translations so we re-fetch it next render.
        (0, Utils_1.deleteCookie)(Constants_1.Cookies.CATALOG_TRANSLATIONS_REFETCH_TIME);
        throw new Error(`Could not get catalog translations data, received the following error: ${error}`);
    }
});
exports.getCatalogTranslationsData = getCatalogTranslationsData;
const getHeaderData = (pageRef) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(`${Constants_1.AppEndpoints.HEADER}?pageRef=${pageRef}`);
        return (0, Utils_1.mapHeaderData)(result.data);
    }
    catch (error) {
        throw new Error(`Could not get header data, received the following error: ${error}`);
    }
});
exports.getHeaderData = getHeaderData;
const getFooterData = (pageRef) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(`${Constants_1.AppEndpoints.FOOTER}?pageRef=${pageRef}`);
        return (0, Utils_1.mapFooterData)(result.data);
    }
    catch (error) {
        throw new Error(`Could not get footer data, received the following error: ${error}`);
    }
});
exports.getFooterData = getFooterData;
const getServiceCenterLinks = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Constants_1.AppEndpoints.SERVICE_CENTER_LINKS);
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not get service center links, received the following error: ${error}`);
    }
});
exports.getServiceCenterLinks = getServiceCenterLinks;
const getInitData = (pageRef) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(`${Constants_1.AppEndpoints.GET}?pageRef=${pageRef}`);
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not get header data, received the following error: ${error}`);
    }
});
exports.getInitData = getInitData;
const getErrorPagesData = (pageRef) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(`${Constants_1.AppEndpoints.ERROR_PAGES}?pageRef=${pageRef}`);
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not get error pages data, received the following error: ${error}`);
    }
});
exports.getErrorPagesData = getErrorPagesData;
const getApiKeys = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(`${Constants_1.AppEndpoints.API_KEYS}`);
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not get api keys, received the following error: ${error}`);
    }
});
exports.getApiKeys = getApiKeys;
