"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.digitsAfterDecimal = void 0;
const digitsAfterDecimal = (num) => {
    var _a;
    if (Number.isInteger(num)) {
        return 0;
    }
    const arr = (_a = num === null || num === void 0 ? void 0 : num.toString()) === null || _a === void 0 ? void 0 : _a.split('.');
    return arr[1].length;
};
exports.digitsAfterDecimal = digitsAfterDecimal;
