"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateTransactionStatus = exports.transactionStatusesDropdown = void 0;
const Types_1 = require("Types");
const OPEN = '/haldex/orders_history/invoice_statuses/open';
const DELIVERED = '/haldex/orders_history/order_statuses/delivered';
const INVOICED = '/haldex/orders_history/order_statuses/invoiced';
const OPEN_ORDER = '/haldex/orders_history/order_statuses/open_order';
const PAID = '/haldex/orders_history/order_statuses/paid';
const ALL = '/haldex/invoices_history/filters/all_statuses';
const transactionStatusesDropdown = (t, isInvoices = false) => {
    return isInvoices
        ? [
            { name: t(ALL), id: '0' },
            { name: t(OPEN), id: '1' },
            { name: t(PAID), id: '2' }
        ]
        : [
            { name: t(ALL), id: '0' },
            { name: t(OPEN), id: '1' },
            { name: t(DELIVERED), id: '2' },
            { name: t(INVOICED), id: '3' },
            { name: t(OPEN_ORDER), id: '4' },
            { name: t(PAID), id: '5' }
        ];
};
exports.transactionStatusesDropdown = transactionStatusesDropdown;
const translateTransactionStatus = (t, orderStatus) => {
    switch (orderStatus) {
        case Types_1.OrderStatuses.DELIVERED:
            return t(DELIVERED);
        case Types_1.OrderStatuses.INVOICED:
            return t(INVOICED);
        case Types_1.OrderStatuses.OPEN_ORDER:
            return t(OPEN_ORDER);
        case Types_1.OrderStatuses.OPEN:
            return t(OPEN);
        case Types_1.OrderStatuses.PAID:
            return t(PAID);
        default:
            return orderStatus;
    }
};
exports.translateTransactionStatus = translateTransactionStatus;
