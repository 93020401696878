"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordChangeBlockSchema = exports.PasswordChangeFields = void 0;
const yup = __importStar(require("yup"));
var PasswordChangeFields;
(function (PasswordChangeFields) {
    PasswordChangeFields["NEW_PASSWORD"] = "newPassword";
    PasswordChangeFields["CONFIRM_PASSWORD"] = "confirmNewPassword";
    PasswordChangeFields["CURRENT_PASSWORD"] = "currentPassword";
})(PasswordChangeFields = exports.PasswordChangeFields || (exports.PasswordChangeFields = {}));
const MIN_LENGTH = 12;
const passwordChangeBlockSchema = (t) => yup.object({
    [PasswordChangeFields.NEW_PASSWORD]: yup
        .string()
        .matches(/^(?=.*[A-Z]).+$/, {
        message: {
            message: t('/haldex/my_profile/password/requirements_upper'),
            field: PasswordChangeFields.NEW_PASSWORD
        }
    })
        .matches(/^(?=.*[ !""#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\\]).*$/, {
        message: {
            message: t('/haldex/my_profile/password/special_character'),
            field: PasswordChangeFields.NEW_PASSWORD
        }
    })
        .matches(/\d/, {
        message: {
            message: t('/haldex/my_profile/password/requirements_numbers'),
            field: PasswordChangeFields.NEW_PASSWORD
        }
    })
        .min(MIN_LENGTH, { message: t('/haldex/my_profile/password/length'), field: PasswordChangeFields.NEW_PASSWORD })
        .required({ message: t('/haldex/my_profile/new_password/required'), field: PasswordChangeFields.NEW_PASSWORD }),
    [PasswordChangeFields.CONFIRM_PASSWORD]: yup
        .string()
        .test('field-equality', { message: t('/haldex/my_profile/password/not_match'), field: PasswordChangeFields.CONFIRM_PASSWORD }, function (value) {
        const { newPassword } = this.parent;
        return newPassword === value;
    })
        .required({
        message: t('/haldex/my_profile/confirm_password/required'),
        field: PasswordChangeFields.CONFIRM_PASSWORD
    }),
    [PasswordChangeFields.CURRENT_PASSWORD]: yup
        .string()
        .required({
        message: t('/haldex/my_profile/current_password/required'),
        field: PasswordChangeFields.CURRENT_PASSWORD
    })
});
exports.passwordChangeBlockSchema = passwordChangeBlockSchema;
