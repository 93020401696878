"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdditionalCharge = void 0;
const Utils_1 = require("@/Utils");
const getAdditionalCharge = (prices) => {
    var _a, _b;
    const corePrice = (_a = prices === null || prices === void 0 ? void 0 : prices[0]) === null || _a === void 0 ? void 0 : _a.corePrice;
    const currencySymbol = (_b = prices === null || prices === void 0 ? void 0 : prices[0]) === null || _b === void 0 ? void 0 : _b.currencySymbol;
    return corePrice && currencySymbol ? `${currencySymbol}${(0, Utils_1.priceEncoder)(corePrice)}` : '';
};
exports.getAdditionalCharge = getAdditionalCharge;
