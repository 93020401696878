"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shippingTypeTranslateConverter = void 0;
const Types_1 = require("Types");
const shippingTypeTranslateConverter = (type) => {
    switch (type) {
        case Types_1.ShippingTypes.Standard:
            return 'standard';
        case Types_1.ShippingTypes.Rush:
            return 'rush';
        case Types_1.ShippingTypes.AdvantageFreight:
            return 'advantage';
        case Types_1.ShippingTypes.CustomerPickUp:
            return 'customer';
        case Types_1.ShippingTypes.StockOrders:
            return 'stock';
        case Types_1.ShippingTypes.ExpressOrders:
            return 'express';
        case Types_1.ShippingTypes.CustomerCollect:
            return 'customer_collect';
    }
};
exports.shippingTypeTranslateConverter = shippingTypeTranslateConverter;
