"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorPagesData = void 0;
const react_1 = require("react");
const useCurrentPage_1 = require("./useCurrentPage");
const react_redux_1 = require("react-redux");
const app_1 = require("../Endpoints/app");
const useErrorPagesData = () => {
    const page = (0, useCurrentPage_1.useCurrentPage)();
    const [errorPages, setErrorPages] = (0, react_1.useState)();
    const errorPagesData = (0, react_redux_1.useSelector)((state) => state.errorPages);
    (0, react_1.useEffect)(() => {
        if ((errorPagesData === null || errorPagesData === void 0 ? void 0 : errorPagesData.errorPage) || (errorPagesData === null || errorPagesData === void 0 ? void 0 : errorPagesData.notFoundPage)) {
            setErrorPages(errorPagesData);
            return;
        }
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const errorPageDataFetch = yield (0, app_1.getErrorPagesData)((_b = (_a = page === null || page === void 0 ? void 0 : page.contentLink) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString());
            setErrorPages(errorPageDataFetch);
        });
        fetchData();
    }, [page, errorPagesData]);
    return errorPages;
};
exports.useErrorPagesData = useErrorPagesData;
exports.default = exports.useErrorPagesData;
