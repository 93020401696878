"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObjectProperty = void 0;
const getObjectProperty = (obj, properties, defaultValue) => {
    if (!obj)
        return defaultValue;
    const props = properties.split('.');
    let objProp = obj;
    for (let index = 0; index < props.length; index++) {
        if (objProp[props[index]] !== undefined) {
            objProp = objProp[props[index]];
        }
        else {
            objProp = defaultValue;
            break;
        }
    }
    return objProp;
};
exports.getObjectProperty = getObjectProperty;
