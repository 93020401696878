"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const palette_1 = require("./palette");
const typography_1 = require("./typography");
const spacing_1 = require("./spacing");
const breakpoints_1 = require("./breakpoints");
const attributes_1 = require("./attributes");
const constants_1 = require("./constants");
const animations_1 = require("./animations");
const keyframes_1 = require("./keyframes");
const createTheme = ({ palette, typography, attributes, breakpoints, spacing, constants, animations, themeKeyframes }) => ({
    palette,
    typography,
    attributes,
    breakpoints,
    spacing,
    constants,
    animations,
    themeKeyframes
});
exports.theme = createTheme({
    palette: palette_1.palette,
    typography: typography_1.typography,
    attributes: attributes_1.attributes,
    breakpoints: breakpoints_1.breakpoints,
    spacing: spacing_1.spacing,
    constants: constants_1.constants,
    animations: animations_1.animations,
    themeKeyframes: keyframes_1.themeKeyframes
});
