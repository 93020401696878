"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loader = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const rotate = (0, styled_components_1.keyframes) `
  to{transform: rotate(1turn)}
`;
exports.Loader = styled_components_1.default.div `
  height: ${props => props.theme.spacing.m5}px;
  width: ${props => props.theme.spacing.m5}px;
  padding: ${props => props.theme.constants.borderWidth.md}px;
  background: ${props => props.color || props.theme.palette.BLUE_100};
  border-radius: 50%;
  mask: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  mask-composite: source-out;
  ${props => props.isFirefox && 'mask-composite: subtract;'}
  animation: ${rotate} 1s linear infinite;
  ${props => (props === null || props === void 0 ? void 0 : props.width) && `width: ${props.width}px;`}
  ${props => (props === null || props === void 0 ? void 0 : props.height) && `height: ${props.height}px;`}
`;
exports.default = {
    Loader: exports.Loader
};
