"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnits = void 0;
const Types_1 = require("Types");
const useTranslate_1 = require("./useTranslate");
const useUnits = () => {
    const { t } = (0, useTranslate_1.useTranslate)();
    const unitDecode = (unit) => {
        switch (unit) {
            case Types_1.UnitVariants.EACH:
                return t('/haldex/add_to_cart/each');
            case Types_1.UnitVariants.FT:
                return t('/haldex/add_to_cart/ft');
            default:
                return t('/haldex/add_to_cart/each');
        }
    };
    return {
        unitDecode
    };
};
exports.useUnits = useUnits;
