"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callAddToCartEvent = exports.LIST_NAME = void 0;
const gtmEvents_1 = require("@/DataLayer/gtmEvents");
const types_1 = require("@/DataLayer/types");
exports.LIST_NAME = 'Quick order';
const callAddToCartEvent = (data) => {
    const quickOrderItems = Object.values(data.productStatuses).map(child => ({
        code: child.haldexCode.code,
        quantity: child.updateQuantity
    }));
    let addedItems = data.cart.lineItems.filter(item => quickOrderItems.some(quickItem => quickItem.code === item.productId));
    addedItems = addedItems.map(item => {
        const quickItem = quickOrderItems.find(quickItem => quickItem.code === item.productId);
        return Object.assign(Object.assign({}, item), { quantity: quickItem ? quickItem.quantity : 0 });
    });
    (0, gtmEvents_1.commonDataLayerEvent)(addedItems, types_1.EDataLayerEventsNames.ADD_TO_CART, exports.LIST_NAME);
};
exports.callAddToCartEvent = callAddToCartEvent;
