"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetPromo = void 0;
const react_1 = require("react");
const spa_core_1 = require("@episerver/spa-core");
const useGetPromo = (promoRef) => {
    const [promo, setPromo] = (0, react_1.useState)();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const repo = (0, spa_core_1.useIContentRepository)();
    (0, react_1.useEffect)(() => {
        setIsLoading(true);
        const getPromo = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            try {
                const result = yield repo.load(promoRef);
                const promoData = result;
                setPromo({
                    desktopImage: (_a = promoData.desktopImage) === null || _a === void 0 ? void 0 : _a.value.url,
                    mobileImage: (_b = promoData.mobileImage) === null || _b === void 0 ? void 0 : _b.value.url,
                    title: (_c = promoData.title) === null || _c === void 0 ? void 0 : _c.value,
                    link: {
                        url: (_d = promoData.link) === null || _d === void 0 ? void 0 : _d.value[0].href,
                        title: (_e = promoData.link) === null || _e === void 0 ? void 0 : _e.value[0].text
                    }
                });
            }
            catch (ex) {
                // silence
            }
            setIsLoading(false);
        });
        getPromo();
    }, [setPromo, setIsLoading, repo, promoRef]);
    return { promo, isLoading };
};
exports.useGetPromo = useGetPromo;
