"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTimePeriod = void 0;
const generateTimePeriod = (start, end, format) => {
    let timePeriod;
    if (start.isSame(end, 'day')) {
        timePeriod = start.format(format + ' H:mm') + ' CET -' + end.format(' H:mm') + ' CET';
    }
    else {
        timePeriod = start.format(format + ' H:mm') + ' CET -' + end.format(` ${format} H:mm`) + ' CET';
    }
    return timePeriod;
};
exports.generateTimePeriod = generateTimePeriod;
