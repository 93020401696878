"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHostUrl = void 0;
const GetInitialData_1 = require("../GetInitialData");
const isSsr = () => {
    return !!(0, GetInitialData_1.getInitialData)();
};
const getUrlFromInitData = () => {
    var _a;
    const initData = (0, GetInitialData_1.getInitialData)();
    const website = (_a = initData.Website) === null || _a === void 0 ? void 0 : _a.Name;
    if (!website) {
        return '';
    }
    if (website.startsWith('http')) {
        return website;
    }
    let protocol = window === null || window === void 0 ? void 0 : window.location.protocol;
    if (!protocol.endsWith(':')) {
        protocol = protocol + ':';
    }
    const url = `${protocol}//${website}`;
    return url;
};
const getUrlFromCurrentWindow = () => {
    const location = window === null || window === void 0 ? void 0 : window.location;
    if (!location) {
        return '';
    }
    const { origin } = location;
    return origin;
};
const getHostUrl = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    let url = process.env.EPI_URL || 'http://haldex-dxp-ecommerce';
    if (!isDevelopment) {
        url = isSsr() ? getUrlFromInitData() || getUrlFromCurrentWindow() : getUrlFromCurrentWindow();
    }
    if (url.endsWith('/')) {
        url = url.slice(0, -1);
    }
    return url;
};
exports.getHostUrl = getHostUrl;
