"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attributes = void 0;
exports.attributes = {
    borderRadius: {
        xss: 2,
        xs: 4,
        sm: 8,
        lg: 16,
        xl: 22,
        xxl: 36
    },
    zIndex: {
        sm: 1,
        gradientOverlay: 5,
        lightbox: 20,
        header: 10,
        overHeader: 13,
        headerBackground: 9,
        popupBackground: 11,
        searchBackground: 12,
        expressBanner: 13
    },
    transition: {
        slow: '0.4s',
        fast: '0.2s'
    },
    boxShadow: {
        regular: '0px 3px 4px rgba(0, 0, 0, 0.06)',
        medium: '0px 5px 7px 1px rgba(0, 0, 0, 0.05)',
        top: '0px -4px 16px 8px rgba(0, 0, 0, 0.15)',
        top2: '0px 4px 16px 8px rgba(0, 0, 0, 0.15)',
        myHaldexDropdown: '0px 1px 6px rgba(0, 0, 0, 0.15)'
    },
    outlineSize: {
        sm: 2
    },
    aspectRatio: {
        wide: '16 / 9'
    }
};
