"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled = __importStar(require("./styled"));
const Typography_1 = require("../../UI/Typography");
const Theme_1 = require("../../../Theme");
const Types_1 = require("Types");
const WithResponsiveColumns_1 = require("../../UI/ResponsiveColumns/WithResponsiveColumns");
const VerticalImageTextBlock = ({ desktopImage, mobileImage, title, body, link, linkText, linkFunction, textAlignment, columns, isPromo, noTextOnImage }) => {
    var _a;
    const pictureRef = (0, react_1.useRef)(null);
    const [imageHeight, setImageHeight] = (0, react_1.useState)(0);
    const [textOnImageState, setTextOnImageState] = (0, react_1.useState)(false);
    const typedTextAlignment = textAlignment || Types_1.TextAlignment.LEFT;
    (0, react_1.useEffect)(() => {
        const resizeHandler = () => {
            if (pictureRef.current) {
                setImageHeight((pictureRef.current.clientWidth / 16) * 9);
            }
            if (window.innerWidth < Theme_1.theme.breakpoints.lg || columns < 8 || noTextOnImage) {
                setTextOnImageState(false);
            }
            else {
                setTextOnImageState(true);
            }
        };
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);
    const content = (react_1.default.createElement(styled.ExtraMarginCol, { extraMargin: textOnImageState, columns: columns, link: !!linkText, isPromo: isPromo },
        desktopImage && (react_1.default.createElement(styled.Picture, { ref: pictureRef, height: imageHeight },
            mobileImage && react_1.default.createElement("source", { media: `(max-width: ${Theme_1.theme.breakpoints.sm - 1}px)`, srcSet: mobileImage }),
            react_1.default.createElement("source", { media: `(min-width: ${Theme_1.theme.breakpoints.sm}px)`, srcSet: desktopImage }),
            react_1.default.createElement(styled.Image, { src: desktopImage, alt: 'test' }))),
        !textOnImageState && (react_1.default.createElement(styled.TextContainer, { textAlignment: typedTextAlignment },
            title && (react_1.default.createElement(styled.Title, { type: Typography_1.Types.H4, color: Theme_1.theme.palette.LIGHT_BLACK }, title)),
            body && react_1.default.createElement(styled.Description, { body: body }),
            linkText && react_1.default.createElement(styled.Button, { variant: Types_1.ButtonVariants.LINK_BUTTON }, linkText))),
        body && textOnImageState && (react_1.default.createElement(styled.TextOnImageCol, { col: 6, textOnImage: textOnImageState },
            react_1.default.createElement(styled.Description, { textOnImage: textOnImageState, body: body })))));
    const onParamsClick = () => {
        let target = Types_1.Target.SELF;
        if (link === null || link === void 0 ? void 0 : link.target) {
            target = link.target;
        }
        window.open(link.href, target);
    };
    const isLinkWithParams = (_a = link === null || link === void 0 ? void 0 : link.href) === null || _a === void 0 ? void 0 : _a.includes('?');
    return isLinkWithParams ? (react_1.default.createElement(styled.LinkWithParamsHOC, { onClick: onParamsClick }, content)) : linkText ? (react_1.default.createElement(styled.LinkHOC, { href: link.href, target: link.target, onClick: linkFunction && linkFunction }, content)) : (content);
};
exports.default = (0, WithResponsiveColumns_1.WithResponsiveColumns)(VerticalImageTextBlock);
