"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verticalImageColsDistr = exports.generalColsDistr = void 0;
exports.generalColsDistr = new Map([
    [
        100,
        {
            container: 100,
            main: {
                xl: 12,
                md: 12,
                sm: 12,
            },
        },
    ],
    [
        50,
        {
            container: 50,
            main: {
                xl: 12,
                md: 12,
                sm: 12,
            },
        },
    ],
    [
        33,
        {
            container: 33,
            main: {
                xl: 12,
                md: 12,
                sm: 12,
            },
        },
    ],
    [
        25,
        {
            container: 25,
            main: {
                xl: 12,
                md: 12,
                sm: 12,
            },
        },
    ],
]);
exports.verticalImageColsDistr = new Map([
    [
        100,
        {
            container: 100,
            double: {
                md: 6,
            },
            triple: {
                md: 4,
            },
            quadruple: {
                md: 3
            }
        },
    ],
]);
