"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSpaces = void 0;
const deleteSpaces = (str) => {
    const strArr = str.split('');
    for (let i = 0; i < strArr.length; i++) {
        const char = strArr[i];
        if (char === ' ') {
            strArr.splice(i, 1);
            i--;
        }
    }
    return strArr.join('');
};
exports.deleteSpaces = deleteSpaces;
