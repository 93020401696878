"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateAddToCartPriceString = void 0;
const Utils_1 = require("@/Utils");
const generateAddToCartPriceString = (prices, t) => {
    return !prices || prices[0].itemPrice === 0
        ? t('/haldex/add_to_cart/no_price')
        : `${(0, Utils_1.currencyEncoder)(prices[0].currency)} ${(0, Utils_1.priceEncoder)(prices[0].itemPrice)}`;
};
exports.generateAddToCartPriceString = generateAddToCartPriceString;
