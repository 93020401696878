"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SITE_LOCALE = exports.CATALOG_LANGUAGES_DROPDOWN_VALUES = exports.LANGUAGE_COOKIE = exports.LANGUAGES = exports.LOCALES = exports.LangNames = void 0;
var LangNames;
(function (LangNames) {
    LangNames["EN"] = "en";
    LangNames["DE"] = "de";
    LangNames["ES"] = "es";
    LangNames["FR"] = "fr";
    LangNames["PT"] = "pt";
    LangNames["TR"] = "tr";
    LangNames["PL"] = "pl";
    LangNames["IT"] = "it";
    LangNames["NL"] = "nl";
})(LangNames = exports.LangNames || (exports.LangNames = {}));
exports.LOCALES = {
    en: 'en_US',
    de: 'de_DE',
    es: 'es_ES',
    fr: 'fr_FR'
};
exports.LANGUAGES = ['en', 'de', 'es', 'fr'];
exports.LANGUAGE_COOKIE = 'Language';
// TODO: Add turkish when data from findex will be available
// { name: 'Türkçe', code: 'tr', id: 8 }
exports.CATALOG_LANGUAGES_DROPDOWN_VALUES = [
    { name: 'Deutsch', code: 'de', id: 0 },
    { name: 'English', code: 'en', id: 1 },
    { name: 'Español', code: 'es', id: 2 },
    { name: 'Français', code: 'fr', id: 3 },
    { name: 'Italiano', code: 'it', id: 4 },
    { name: 'Nederlands', code: 'nl', id: 5 },
    { name: 'Polski', code: 'pl', id: 6 },
    { name: 'Português', code: 'pt', id: 7 }
];
exports.DEFAULT_SITE_LOCALE = 'en';
