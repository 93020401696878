"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = exports.Label = exports.Required = exports.Arrow = exports.Star = exports.DropdownListItem = exports.DropdownListContainer = exports.SelectHeaderInput = exports.SelectHeaderButton = exports.SelectHeader = exports.SelectContainer = exports.Select = void 0;
const svg_components_1 = require("@/assets/svg-components");
const styled_components_1 = __importStar(require("styled-components"));
exports.Select = styled_components_1.default.div `
  margin-top: ${props => props.theme.spacing.m3}px;
  width: 100%;
`;
exports.SelectContainer = styled_components_1.default.div `
  position: relative;
`;
exports.SelectHeader = styled_components_1.default.div `
  position: relative;
  width: 100%;
  overflow: hidden;
`;
exports.SelectHeaderButton = styled_components_1.default.button `
  border: 0;
  outline: 0;
  width: 40px;
  background-color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  height: calc(100% - 2px);
  transform: translateY(-50%);
  right: 2px;
  cursor: pointer;
`;
exports.SelectHeaderInput = styled_components_1.default.input `
  padding: 0 ${props => props.theme.spacing.s3}px;
  min-height: 44px;
  font-size: ${props => props.theme.typography.size.s3}px;
  border: 1px solid ${props => props.theme.palette.BLUE_GRAY_40};
  border-radius: ${props => props.theme.constants.borderRadius.xss}px;
  width: 100%;
  &:focus-visible {
    outline: ${props => props.theme.palette.ORANGE_100};
    border: 1px solid ${props => props.theme.palette.ORANGE_100};
  }
`;
exports.DropdownListContainer = styled_components_1.default.div `
  margin-top: ${props => props.theme.spacing.xs}px;
  border: 1px solid ${props => props.theme.palette.BLUE_GRAY_40};
  max-height: ${props => props.theme.constants.SelectDropdownHeight.xxl}px;
  border-radius: ${props => props.theme.constants.borderRadius.xss}px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: ${props => props.theme.spacing.s1}px;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.palette.LIGHT_GRAY};
    border-radius: ${props => props.theme.spacing.s1}px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.palette.MEDIUM_GRAY};
    border-radius: ${props => props.theme.spacing.s1}px;
  }
  width: 100%;
  border: 1px solid #e6e6e6;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${props => props.theme.constants.zindex.dropdown};
  background-color: #fff;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  box-shadow: ${props => props.theme.attributes.boxShadow.medium};
`;
exports.DropdownListItem = styled_components_1.default.div `
  padding: ${props => props.theme.spacing.s3}px;
  border-bottom: ${props => (props.isLast ? `1px solid ${props.theme.palette.BLUE_GRAY_40}` : 'none')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: ${props => props.theme.spacing.s2}px;

  &:hover {
    background-color: ${props => props.theme.palette.BLUE_100};
    color: white;
  }
`;
exports.Star = styled_components_1.default.div `
  > svg {
    &:hover {
      > path {
        stroke: #be9b1d;
        fill: ${props => props.theme.palette.YELLOW_100};
        fill-opacity: 1;
      }
    }
    > path {
      stroke: ${props => (props.isFavorite ? '#BE9B1D' : '#909EA6')};
      fill: ${props => (props.isFavorite ? props.theme.palette.YELLOW_100 : 'white')};
      fill-opacity: 1;
    }
  }
`;
exports.Arrow = (0, styled_components_1.default)(svg_components_1.ArrowIcon) `
  flex-shrink: 0;
  height: ${props => props.theme.constants.SelectDropdownIconHeight.md}px;
  ${props => (props.isOpen ? 'transform: rotate(180deg);' : null)}
`;
exports.Required = styled_components_1.default.span `
  display: inline;
  color: ${props => props.theme.palette.RED_100};
`;
exports.Label = styled_components_1.default.label `
  > div {
    padding-bottom: ${props => props.theme.spacing.s2}px;
  }
`;
const spin = (0, styled_components_1.keyframes) `
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
exports.Spinner = styled_components_1.default.div `
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: ${props => props.theme.palette.BLUE_100};
  border-left-color: ${props => props.theme.palette.BLUE_100};
  border-bottom-color: #fff;
  border-right-color: #fff;
  animation: ${spin} 1s linear infinite;
`;
exports.default = {
    Select: exports.Select,
    SelectContainer: exports.SelectContainer,
    DropdownListContainer: exports.DropdownListContainer,
    SelectHeader: exports.SelectHeader,
    SelectHeaderInput: exports.SelectHeaderInput,
    SelectHeaderButton: exports.SelectHeaderButton,
    DropdownListItem: exports.DropdownListItem,
    Arrow: exports.Arrow,
    Star: exports.Star,
    Label: exports.Label,
    Spinner: exports.Spinner
};
