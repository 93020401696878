"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandlePrint = void 0;
const react_1 = require("react");
const react_to_print_1 = require("react-to-print");
const useHandlePrint = () => {
    const printRef = (0, react_1.useRef)();
    const handlePrint = (0, react_to_print_1.useReactToPrint)({
        content: () => printRef.current
    });
    const handleUserKeyPress = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyP') {
            event.preventDefault();
            handlePrint();
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('keydown', handleUserKeyPress);
        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, []);
    return { printRef, handlePrint };
};
exports.useHandlePrint = useHandlePrint;
