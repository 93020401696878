"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyOrderToCart = exports.getClaimStatus = exports.getInvoiceDetails = exports.getOrderDetails = exports.getCachedInvoicesHistory = exports.getInvoicesHistory = exports.getCachedOrdersHistory = exports.getOrdersHistory = void 0;
const Endpoints_1 = require("@/Constants/Endpoints");
const request_1 = require("./request");
const getOrdersHistory = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET + params);
    return result;
});
exports.getOrdersHistory = getOrdersHistory;
const getCachedOrdersHistory = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_CACHED + params);
    return result;
});
exports.getCachedOrdersHistory = getCachedOrdersHistory;
const getInvoicesHistory = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_INVOICES + params);
    return result;
});
exports.getInvoicesHistory = getInvoicesHistory;
const getCachedInvoicesHistory = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_CACHED_INVOICES + params);
    return result;
});
exports.getCachedInvoicesHistory = getCachedInvoicesHistory;
const getOrderDetails = (params) => (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_ORDER_DETAILS + params);
exports.getOrderDetails = getOrderDetails;
const getInvoiceDetails = (params) => (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_INVOICE_DETAILS + params);
exports.getInvoiceDetails = getInvoiceDetails;
const getClaimStatus = (params) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.GET_CLAIM_STATUS + params);
        return result;
    }
    catch (error) {
        const err = error;
        return err.response.data;
    }
});
exports.getClaimStatus = getClaimStatus;
const copyOrderToCart = (saleId) => (0, request_1.request)(Endpoints_1.OrdersHistoryEndpoints.COPY_TO_CART + saleId + '/copy-to-cart', request_1.RequestType.PATCH);
exports.copyOrderToCart = copyOrderToCart;
