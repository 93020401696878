"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkAsButtonVariants = void 0;
var LinkAsButtonVariants;
(function (LinkAsButtonVariants) {
    LinkAsButtonVariants["CTA_BUTTON"] = "cta-button";
    LinkAsButtonVariants["LINK_BUTTON"] = "link-button";
    LinkAsButtonVariants["LINK_BUTTON_BIG"] = "link-button-big";
    LinkAsButtonVariants["RESET_BUTTON"] = "reset-button";
    LinkAsButtonVariants["LAYOUT_BUTTON"] = "layout-button";
})(LinkAsButtonVariants = exports.LinkAsButtonVariants || (exports.LinkAsButtonVariants = {}));
