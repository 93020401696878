"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeLanguageLocaleInUrl = void 0;
const changeLanguageLocaleInUrl = (startUrl, lang) => {
    const pathSegments = startUrl.split('/').filter(Boolean);
    pathSegments[0] = lang;
    const newPathname = pathSegments.join('/');
    return '/' + newPathname;
};
exports.changeLanguageLocaleInUrl = changeLanguageLocaleInUrl;
