"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PressReleaseType = void 0;
var PressReleaseType;
(function (PressReleaseType) {
    PressReleaseType[PressReleaseType["Undefined"] = 0] = "Undefined";
    PressReleaseType[PressReleaseType["Annual Financial Statement"] = 1] = "Annual Financial Statement";
    PressReleaseType[PressReleaseType["Annual Report"] = 2] = "Annual Report";
    PressReleaseType[PressReleaseType["Press Release"] = 3] = "Press Release";
    PressReleaseType[PressReleaseType["Interim Report"] = 4] = "Interim Report";
    PressReleaseType[PressReleaseType["Invitation"] = 5] = "Invitation";
    PressReleaseType[PressReleaseType["Newsletter"] = 6] = "Newsletter";
})(PressReleaseType = exports.PressReleaseType || (exports.PressReleaseType = {}));
