"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreviousPageLink = void 0;
const getPreviousPageLink = (breadcrumbs) => {
    if ((breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) > 1) {
        return breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs[(breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length) - 2].href;
    }
    return '/';
};
exports.getPreviousPageLink = getPreviousPageLink;
