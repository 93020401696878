"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currencyEncoder = void 0;
const Types_1 = require("Types");
const currencyEncoder = (currencyCode) => {
    switch (currencyCode) {
        case Types_1.CurrencyCodeEnum.EUR:
            return '€';
        case Types_1.CurrencyCodeEnum.USD:
            return '$';
        case Types_1.CurrencyCodeEnum.GBP:
            return '£';
        default:
            return '$';
    }
};
exports.currencyEncoder = currencyEncoder;
