"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentTranslateType = exports.DocTypes = void 0;
var DocTypes;
(function (DocTypes) {
    DocTypes["None"] = "None";
    DocTypes["Brochure"] = "Brochure";
    DocTypes["TechSpec"] = "TechSpec";
    DocTypes["InstallationGuide"] = "InstallationGuide";
    DocTypes["UserGuide"] = "UserGuide";
    DocTypes["Other"] = "Other";
    DocTypes["Form"] = "Form";
    DocTypes["Bulletin"] = "Bulletin";
    DocTypes["Catalog"] = "Catalog";
    DocTypes["Countercards"] = "Countercards";
    DocTypes["CrossReference"] = "CrossReference";
    DocTypes["FleetSheet"] = "FleetSheet";
    DocTypes["Folder"] = "Folder";
    DocTypes["InstructionSheets"] = "InstructionSheets";
    DocTypes["LineCard"] = "LineCard";
    DocTypes["PolicyManual"] = "PolicyManual";
    DocTypes["Schematic"] = "Schematic";
    DocTypes["ServiceManual"] = "ServiceManual";
    DocTypes["Troubleshooting"] = "Troubleshooting";
    DocTypes["WallChart"] = "WallChart";
    DocTypes["Warranty"] = "Warranty";
    DocTypes["QuarterlyReport"] = "QuarterlyReport";
    DocTypes["AnnualReport"] = "AnnualReport";
    DocTypes["Presentation"] = "Presentation";
    DocTypes["Miscellaneous"] = "Miscellaneous";
})(DocTypes = exports.DocTypes || (exports.DocTypes = {}));
const translationPath = '/haldex/document_types/';
const DocumentTranslateType = (type) => {
    return DocTypes[type] ? translationPath + DocTypes[type] : null;
};
exports.DocumentTranslateType = DocumentTranslateType;
