"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeFilterParamString = exports.getFilterParamsFromUrl = exports.getSchematicsFilters = exports.getLiteratureFilters = exports.getFilters = void 0;
var DocFilterTypes;
(function (DocFilterTypes) {
    DocFilterTypes["CONNECTED_TO"] = "ConnectedTos";
    DocFilterTypes["DOC_TYPE"] = "DocTypeString";
    DocFilterTypes["DOC_LANGUAGE"] = "DocLanguages";
    DocFilterTypes["TAGS"] = "Tags";
})(DocFilterTypes || (DocFilterTypes = {}));
const getFilters = (item, nameAsId) => {
    let id = 0;
    const filterItem = [];
    const newFilter = {
        label: (item === null || item === void 0 ? void 0 : item.heading) || (item === null || item === void 0 ? void 0 : item.name),
        key: item === null || item === void 0 ? void 0 : item.name,
        items: filterItem
    };
    item === null || item === void 0 ? void 0 : item.options.forEach(option => {
        var _a;
        id++;
        (_a = newFilter === null || newFilter === void 0 ? void 0 : newFilter.items) === null || _a === void 0 ? void 0 : _a.push({
            id: nameAsId ? option.value : `${item.heading || item.name}-${id.toString()}`,
            initialName: option.value,
            name: option.value,
            isActive: false,
            key: item.name,
            results: Number(option === null || option === void 0 ? void 0 : option.count)
        });
    });
    return newFilter;
};
exports.getFilters = getFilters;
const getLiteratureFilters = (item) => {
    let id = 0;
    const filterItem = [];
    const newFilter = {
        label: (item === null || item === void 0 ? void 0 : item.heading) || (item === null || item === void 0 ? void 0 : item.name),
        key: item === null || item === void 0 ? void 0 : item.name,
        items: filterItem
    };
    item === null || item === void 0 ? void 0 : item.options.forEach(option => {
        var _a;
        id++;
        let name = (option === null || option === void 0 ? void 0 : option.text) || option.value;
        if (item.name === DocFilterTypes.DOC_LANGUAGE) {
            name = name[0].toUpperCase() + name.substring(1);
        }
        (_a = newFilter === null || newFilter === void 0 ? void 0 : newFilter.items) === null || _a === void 0 ? void 0 : _a.push({
            id: `${item.heading || item.name}-${id.toString()}`,
            initialName: option.value,
            name,
            isActive: false,
            key: item.name,
            results: Number(option === null || option === void 0 ? void 0 : option.count)
        });
    });
    return newFilter;
};
exports.getLiteratureFilters = getLiteratureFilters;
const getSchematicsFilters = (item, setFromValue, setToValue, setMinMaxRange, isFirstRequest) => {
    var _a, _b;
    let id = 0;
    const filterItem = [];
    const newFilter = {
        label: (item === null || item === void 0 ? void 0 : item.heading) || (item === null || item === void 0 ? void 0 : item.name),
        key: item === null || item === void 0 ? void 0 : item.name,
        items: filterItem
    };
    (_a = item === null || item === void 0 ? void 0 : item.options) === null || _a === void 0 ? void 0 : _a.forEach(option => {
        var _a;
        id++;
        let name = (option === null || option === void 0 ? void 0 : option.text) || option.value;
        let initialName = option.value;
        if (item.name === DocFilterTypes.TAGS) {
            name = name[0].toUpperCase() + name.substring(1).toLocaleLowerCase();
            initialName = initialName[0].toUpperCase() + initialName.substring(1).toLocaleLowerCase();
        }
        (_a = newFilter === null || newFilter === void 0 ? void 0 : newFilter.items) === null || _a === void 0 ? void 0 : _a.push({
            id: `${item.heading || item.name}-${id.toString()}`,
            initialName: initialName,
            name,
            isActive: false,
            key: item.name,
            results: Number(option === null || option === void 0 ? void 0 : option.count)
        });
    });
    (_b = item === null || item === void 0 ? void 0 : item.rangeOptions) === null || _b === void 0 ? void 0 : _b.forEach(option => {
        var _a;
        id++;
        let name = (option === null || option === void 0 ? void 0 : option.text) || option.value;
        let initialName = option.value;
        if (item.name === DocFilterTypes.TAGS) {
            name = name[0].toUpperCase() + name.substring(1).toLocaleLowerCase();
            initialName = initialName[0].toUpperCase() + initialName.substring(1).toLocaleLowerCase();
        }
        if (isFirstRequest) {
            setFromValue(option.min);
            setToValue(option.max);
            setMinMaxRange({ min: option.min, max: option.max });
        }
        (_a = newFilter === null || newFilter === void 0 ? void 0 : newFilter.items) === null || _a === void 0 ? void 0 : _a.push({
            id: `${item.heading || item.name}-${id.toString()}`,
            initialName: initialName,
            name,
            isActive: false,
            key: item.name,
            results: Number(option === null || option === void 0 ? void 0 : option.count),
            max: option === null || option === void 0 ? void 0 : option.max,
            min: option === null || option === void 0 ? void 0 : option.min,
            isRange: true
        });
    });
    return newFilter;
};
exports.getSchematicsFilters = getSchematicsFilters;
const getFilterParamsFromUrl = (filterArrToCompare) => {
    var _a;
    const urlParams = new URLSearchParams((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.search);
    const uniqueKeys = [...new Set(filterArrToCompare === null || filterArrToCompare === void 0 ? void 0 : filterArrToCompare.map(item => item.key))];
    const values = [];
    uniqueKeys === null || uniqueKeys === void 0 ? void 0 : uniqueKeys.forEach(key => {
        const paramValues = urlParams.get(key);
        if (paramValues) {
            const params = paramValues.split('","');
            params.forEach(param => {
                values.push({
                    key,
                    value: param
                });
            });
        }
    });
    const newActiveFilter = [];
    filterArrToCompare === null || filterArrToCompare === void 0 ? void 0 : filterArrToCompare.forEach(filter => {
        filter.items.forEach((item) => {
            if (values.some(param => param.key === item.key && param.value === item.name)) {
                newActiveFilter.push(item);
            }
        });
    });
    return newActiveFilter;
};
exports.getFilterParamsFromUrl = getFilterParamsFromUrl;
const makeFilterParamString = (filters) => {
    const params = {};
    filters === null || filters === void 0 ? void 0 : filters.forEach(filter => {
        var _a;
        if ((_a = params[filter.key]) === null || _a === void 0 ? void 0 : _a.filters) {
            params[filter.key].filters.push(filter.name);
        }
        else {
            params[filter.key] = { filters: [filter.name] };
        }
    });
    let paramString = '';
    Object.entries(params).forEach((filter, i, _, [key, values] = filter) => {
        var _a;
        paramString += `&${key}=${encodeURIComponent((_a = values === null || values === void 0 ? void 0 : values.filters) === null || _a === void 0 ? void 0 : _a.join('","'))}`;
    });
    return paramString;
};
exports.makeFilterParamString = makeFilterParamString;
