"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateParam = exports.getInitialFilters = void 0;
const getInitialFilters = () => {
    const urlParams = new URLSearchParams(window === null || window === void 0 ? void 0 : window.location.search);
    const initialFilters = [];
    for (const key of urlParams.keys()) {
        const paramValues = urlParams.get(key);
        if (paramValues) {
            const params = paramValues.split('","');
            params.forEach(param => {
                initialFilters.push({
                    key,
                    name: encodeURIComponent(param)
                });
            });
        }
    }
    return initialFilters;
};
exports.getInitialFilters = getInitialFilters;
const generateParam = (ignoreAttributes) => {
    const filterAttributes = [];
    const filterCustomerSpecifics = [];
    const filterProductionStatuses = [];
    const filterProductLines = [];
    const filterCategories = [];
    let query = '';
    const activeFilters = (0, exports.getInitialFilters)();
    activeFilters.forEach(filter => {
        switch (filter.key) {
            case 'query':
                query = filter.name;
                return;
            case 'ProductionStatus':
                filterProductionStatuses.push(filter.name);
                return;
            case 'CustomizableStatus':
                filterCustomerSpecifics.push(filter.name);
                return;
            case 'Brand':
                filterProductLines.push(filter.name);
                return;
            case 'Category':
                filterCategories.push(filter.name);
                return;
            default:
                if (filterAttributes.length > 0) {
                    filterAttributes.forEach(activeFilter => {
                        if (activeFilter.AttributeName === filter.key) {
                            activeFilter.Values.push(filter.name);
                        }
                        else if (!(ignoreAttributes === null || ignoreAttributes === void 0 ? void 0 : ignoreAttributes.includes(filter === null || filter === void 0 ? void 0 : filter.key))) {
                            filterAttributes.push({
                                AttributeName: filter.key,
                                Values: [filter.name]
                            });
                        }
                    });
                }
                else if (!(ignoreAttributes === null || ignoreAttributes === void 0 ? void 0 : ignoreAttributes.includes(filter === null || filter === void 0 ? void 0 : filter.key))) {
                    filterAttributes.push({
                        AttributeName: filter.key,
                        Values: [filter.name]
                    });
                }
        }
    });
    const activeFilter = {};
    if (filterAttributes.length > 0)
        activeFilter.filterAttributes = filterAttributes;
    if (filterCustomerSpecifics.length > 0)
        activeFilter.filterCustomerSpecifics = filterCustomerSpecifics;
    if (filterProductionStatuses.length > 0)
        activeFilter.filterProductionStatuses = filterProductionStatuses;
    if (filterProductLines.length > 0)
        activeFilter.filterProductLines = filterProductLines;
    if (filterCategories.length > 0)
        activeFilter.filterCategories = filterCategories;
    if (query.length > 0)
        activeFilter.query = query;
    return activeFilter;
};
exports.generateParam = generateParam;
