"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BootstrapBreakpoints = exports.breakpoints = void 0;
exports.breakpoints = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1060,
    xxl: 1200,
    xxxl: 1440,
    pressReleaseSpecial: 500
};
var BootstrapBreakpoints;
(function (BootstrapBreakpoints) {
    BootstrapBreakpoints["XXL"] = "xxl";
    BootstrapBreakpoints["XL"] = "xl";
    BootstrapBreakpoints["LG"] = "lg";
    BootstrapBreakpoints["MD"] = "md";
    BootstrapBreakpoints["SM"] = "sm";
    BootstrapBreakpoints["XS"] = "xs";
    BootstrapBreakpoints["COL"] = "col";
})(BootstrapBreakpoints = exports.BootstrapBreakpoints || (exports.BootstrapBreakpoints = {}));
