"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setErrorPagesData = exports.errorPagesSlice = exports.fetchErrorPages = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const app_1 = require("../../Endpoints/app");
const initialState = {
    notFoundPage: null,
    errorPage: null
};
exports.fetchErrorPages = (0, toolkit_1.createAsyncThunk)('fetchErrorPagesData', (pageId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, app_1.getErrorPagesData)(pageId);
    return response;
}));
exports.errorPagesSlice = (0, toolkit_1.createSlice)({
    name: 'header',
    initialState,
    extraReducers: builder => {
        builder.addCase(exports.fetchErrorPages.fulfilled, (state, action) => {
            state.notFoundPage = action.payload.notFoundPage;
            state.errorPage = action.payload.errorPage;
        });
    },
    reducers: {
        setErrorPagesData: (state, action) => {
            state.notFoundPage = action.payload.notFoundPage;
            state.errorPage = action.payload.errorPage;
        }
    }
});
exports.setErrorPagesData = exports.errorPagesSlice.actions.setErrorPagesData;
exports.default = exports.errorPagesSlice.reducer;
