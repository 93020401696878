"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setExpressOrdersDaysOff = exports.setExpressAvailable = exports.setIsScrollActive = exports.siteSettingsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isScrollActive: false,
    expressAvailable: false,
    expressOrdersDaysOff: []
};
exports.siteSettingsSlice = (0, toolkit_1.createSlice)({
    name: 'siteSettings',
    initialState,
    reducers: {
        setIsScrollActive: (state, action) => {
            state.isScrollActive = action.payload;
        },
        setExpressAvailable: (state, action) => {
            state.expressAvailable = action.payload;
        },
        setExpressOrdersDaysOff: (state, action) => {
            state.expressOrdersDaysOff = action.payload;
        }
    }
});
_a = exports.siteSettingsSlice.actions, exports.setIsScrollActive = _a.setIsScrollActive, exports.setExpressAvailable = _a.setExpressAvailable, exports.setExpressOrdersDaysOff = _a.setExpressOrdersDaysOff;
exports.default = exports.siteSettingsSlice.reducer;
