"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerCalendarLocale = void 0;
const locale_1 = require("date-fns/locale");
const react_datepicker_1 = require("react-datepicker");
const Utils_1 = require("../Utils");
const registerCalendarLocale = () => {
    switch ((0, Utils_1.getLanguage)()) {
        case 'en':
            return (0, react_datepicker_1.registerLocale)('en', locale_1.enUS);
        case 'fr':
            return (0, react_datepicker_1.registerLocale)('fr', locale_1.fr);
        case 'de':
            return (0, react_datepicker_1.registerLocale)('de', locale_1.de);
        case 'es':
            return (0, react_datepicker_1.registerLocale)('es', locale_1.es);
        default:
            return (0, react_datepicker_1.registerLocale)('en', locale_1.enUS);
    }
};
exports.registerCalendarLocale = registerCalendarLocale;
