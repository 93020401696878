"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCATION_EU = exports.LOCATION_NA = exports.FILTER_DELAY = exports.MOVE_DELAY = exports.SEARCH_DELAY = void 0;
exports.SEARCH_DELAY = 1000;
exports.MOVE_DELAY = 1000;
exports.FILTER_DELAY = 700;
exports.LOCATION_NA = {
    center: { lat: 40, lng: -105 },
    minMax: {
        latMax: 62,
        latMin: 17,
        lngMax: -59,
        lngMin: -152
    }
};
exports.LOCATION_EU = {
    center: { lat: 49, lng: 13 },
    minMax: {
        latMax: 70,
        latMin: 34,
        lngMax: 46,
        lngMin: -16
    }
};
