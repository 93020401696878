"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cartErrorMessage = void 0;
const Types_1 = require("Types");
const cartErrorMessage = (errorType) => {
    switch (errorType) {
        case Types_1.ErrorTypes.USER_NOT_ACTIVE:
            return ['/haldex/cart_page/checkout_page/user_not_active'];
        case Types_1.ErrorTypes.USER_NOT_AXAPTA_USER:
            return ['/haldex/cart_page/checkout_page/no_axapta_user'];
        case Types_1.ErrorTypes.WRONG_DELIVERY_TYPE:
            return ['/haldex/cart_page/checkout_page/wrong_delivery_type'];
        case Types_1.ErrorTypes.DROP_SHIP_NOT_ALLOWED:
            return ['/haldex/cart_page/checkout_page/drop_ship_not_allowed'];
        case Types_1.ErrorTypes.EMPTY_CART:
            return ['/haldex/cart_page/checkout_page/empty_cart'];
        case Types_1.ErrorTypes.UNDEFINED:
            return ['/haldex/cart_page/checkout_page/general_error'];
        case Types_1.ErrorTypes.GENERAL_ERROR:
            return ['/haldex/cart_page/checkout_page/general_error'];
        case Types_1.ErrorTypes.PLACE_ORDER_IN_PROGRESS:
            return ['/haldex/cart_page/checkout_page/place_order_in_progress'];
        case Types_1.ErrorTypes.INVALID_SHIPPING:
            return ['/haldex/cart_page/checkout_page/invalid_shipping_address'];
        case Types_1.ErrorTypes.INVALID_BILLING:
            return ['/haldex/cart_page/checkout_page/invalid_billing_address'];
        case Types_1.ErrorTypes.WRONG_SHIPMENT_DATE:
            return ['/haldex/cart_page/checkout_page/shipping_dates_error'];
        case Types_1.ErrorTypes.LACK_OF_ITEMS_IN_STOCK:
            return [
                '/haldex/cart_page/checkout_page/express_order_error_1',
                '/haldex/cart_page/checkout_page/express_order_error_2'
            ];
        default:
            return ['/haldex/cart_page/checkout_page/general_error'];
    }
};
exports.cartErrorMessage = cartErrorMessage;
