"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonDirection = exports.ButtonTypes = exports.ButtonVariants = void 0;
var ButtonVariants;
(function (ButtonVariants) {
    ButtonVariants["DEFAULT"] = "regular-button";
    ButtonVariants["SMALL_BUTTON"] = "small-button";
    ButtonVariants["LINK_BUTTON"] = "link-button";
    ButtonVariants["ICON_BUTTON"] = "icon-button";
    ButtonVariants["SUBMIT_BUTTON"] = "submit-button";
    ButtonVariants["CHEVRON_BUTTON"] = "chevron_button";
    ButtonVariants["ONLY_TEXT"] = "only-text";
    ButtonVariants["SUBJECT_BUTTON"] = "subject-button";
})(ButtonVariants = exports.ButtonVariants || (exports.ButtonVariants = {}));
var ButtonTypes;
(function (ButtonTypes) {
    ButtonTypes["SUBMIT"] = "submit";
    ButtonTypes["RESET"] = "reset";
    ButtonTypes["BUTTON"] = "button";
})(ButtonTypes = exports.ButtonTypes || (exports.ButtonTypes = {}));
var ButtonDirection;
(function (ButtonDirection) {
    ButtonDirection["LEFT"] = "left";
    ButtonDirection["RIGHT"] = "right";
})(ButtonDirection = exports.ButtonDirection || (exports.ButtonDirection = {}));
