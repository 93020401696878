"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useThrottle(func, ms) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;
    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }
        func.apply(savedThis, arguments);
        isThrottled = true;
        setTimeout(() => {
            isThrottled = false;
            if (savedArgs || savedThis) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }
    return wrapper;
}
exports.default = useThrottle;
