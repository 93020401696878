"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockWidthsEnum = void 0;
var BlockWidthsEnum;
(function (BlockWidthsEnum) {
    BlockWidthsEnum["ARTICLE_WIDTH"] = "article-width";
    BlockWidthsEnum["WARRANTY_WIDTH"] = "warranty-width";
    BlockWidthsEnum["CONTENT_WIDTH"] = "content-width";
    BlockWidthsEnum["FULL_WIDTH"] = "fullwidth";
})(BlockWidthsEnum = exports.BlockWidthsEnum || (exports.BlockWidthsEnum = {}));
