"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateImageMap = void 0;
const ClickableImageBlock_1 = require("../Components/Block/ClickableImageBlock/ClickableImageBlock");
const Theme_1 = require("../Theme/Theme");
const imageMapTypeSwitch = (imageMapType) => {
    switch (imageMapType) {
        case ClickableImageBlock_1.ImageMapType.CIRCLE:
            return 'circle';
        case ClickableImageBlock_1.ImageMapType.POLYGON:
            return 'poly';
        case ClickableImageBlock_1.ImageMapType.RECTANGLE:
            return 'rect';
        default:
            return 'default';
    }
};
const generateImageMap = (imageAreas, ratio) => {
    const map = [];
    imageAreas.forEach((el, index) => {
        var _a, _b, _c, _d;
        map.push({
            id: `${index}`,
            title: el === null || el === void 0 ? void 0 : el.title,
            shape: imageMapTypeSwitch(el === null || el === void 0 ? void 0 : el.imageMapType),
            fillColor: Theme_1.theme.palette.GREY_100_03T,
            strokeColor: Theme_1.theme.palette.GREY_100_03T,
            coords: (_b = (_a = el === null || el === void 0 ? void 0 : el.coordinates) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map(el => (ratio ? Number(el) * ratio : Number(el))),
            href: (_d = (_c = el === null || el === void 0 ? void 0 : el.linkItems) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.href
        });
    });
    return map;
};
exports.generateImageMap = generateImageMap;
