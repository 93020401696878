"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStructuredMegamenuItems = void 0;
const getStructuredMegamenuItems = (secondLevelItems) => {
    const structuredLevels = [];
    let nodes = [];
    if (secondLevelItems.length === 1) {
        structuredLevels.push([secondLevelItems[0]]);
    }
    else {
        secondLevelItems.forEach(secondItem => {
            if (nodes.length === 3 || secondItem.thirdLevelItems.length > 0) {
                nodes.push(secondItem);
                structuredLevels.push(nodes);
                nodes = [];
            }
            else {
                nodes.push(secondItem);
            }
        });
    }
    if (nodes.length !== 0) {
        structuredLevels.push(nodes);
    }
    return structuredLevels;
};
exports.getStructuredMegamenuItems = getStructuredMegamenuItems;
