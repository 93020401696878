"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageViewEvent = void 0;
const domSafe = () => {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
};
const sendTracking = (values) => {
    if (domSafe()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(values);
    }
};
const pageViewEvent = (pagePath, hostName) => {
    sendTracking({
        event: 'pageview',
        pagePath,
        hostName
    });
};
exports.pageViewEvent = pageViewEvent;
