"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = void 0;
const INTERVAL_DURATION = 2;
const scrollToTop = (scrollDuration) => {
    const scrollStep = window.scrollY / (scrollDuration / INTERVAL_DURATION);
    const scrollInterval = setInterval(() => {
        if (window.scrollY === 0) {
            clearInterval(scrollInterval);
        }
        else {
            window.scrollBy(0, -scrollStep);
        }
    }, INTERVAL_DURATION);
};
exports.scrollToTop = scrollToTop;
