"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateFilterParams = void 0;
const generateFilterParams = (filters, search, replaceState) => {
    let newParams = '';
    window.history.replaceState(null, null, '');
    const params = [];
    filters.forEach(filter => {
        let found = false;
        params.forEach(param => {
            if (param.key === filter.key) {
                if (!param.values.includes(filter.name)) {
                    param.values.push(filter.name);
                }
                found = true;
            }
        });
        if (!found) {
            params.push({
                key: filter.key,
                values: [filter.name]
            });
        }
        if (params.length < 1) {
            params.push({
                key: filter.key,
                values: [filter.name]
            });
        }
    });
    if (search) {
        newParams += `?query=${encodeURIComponent(search)}`;
    }
    params.forEach(param => {
        newParams.charAt(0) === '?'
            ? (newParams += `&${param.key}=${encodeURIComponent(param.values.join('","'))}`)
            : (newParams += `?${param.key}=${encodeURIComponent(param.values.join('","'))}`);
    });
    if (replaceState) {
        newParams
            ? window.history.replaceState(null, null, newParams)
            : window.history.replaceState(null, null, window.location.pathname);
    }
    return newParams;
};
exports.generateFilterParams = generateFilterParams;
