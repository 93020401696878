"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.themeKeyframes = void 0;
const styled_components_1 = require("styled-components");
const widthResize = (percentageFrom, percentageTo) => (0, styled_components_1.keyframes) `
  0% {
    width: ${percentageFrom}%;
  }
  100% {
    width: ${percentageTo}%;
  }
`;
const scaleYIn = () => (0, styled_components_1.keyframes) `
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
`;
const scaleYOut = () => (0, styled_components_1.keyframes) `
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
`;
const fadeIn = () => (0, styled_components_1.keyframes) `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeOut = () => (0, styled_components_1.keyframes) `
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;
const fadeToColor = (palette) => (0, styled_components_1.keyframes) `
  0% {
    background-color: 'transparent';
  }
  100% {
    background-color: ${palette};
  }
`;
const fadeToTransparent = (palette) => (0, styled_components_1.keyframes) `
  0% {
    background-color: ${palette};
  }
  65% {
    background-color: ${palette};
  }
  100% {
    background-color: 'transparent';
  }
`;
const rotation = () => (0, styled_components_1.keyframes) `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
const placeholderShimmer = () => (0, styled_components_1.keyframes) `
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0; 
  }
`;
exports.themeKeyframes = {
    widthResize: widthResize,
    scaleYIn: scaleYIn,
    scaleYOut: scaleYOut,
    fadeIn: fadeIn,
    fadeOut: fadeOut,
    fadeToColor: fadeToColor,
    fadeToTransparent: fadeToTransparent,
    rotation: rotation,
    placeholderShimmer: placeholderShimmer
};
