"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.animations = void 0;
exports.animations = {
    primary: 'all 0.2s ease',
    headerHover: 'all 0.3s ease',
    backgroundOnly: 'background 0.2s ease',
    easeInOut: '0.3s ease-in-out',
    easeShort: '0.3s ease',
    easeMedium: '0.4s ease'
};
