"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchParams = exports.ESearchParams = void 0;
const useMyOrdersPageData_1 = require("../Hooks/useMyOrdersPageData");
const PageController_1 = require("../Components/Page/MyOrdersPage/PageController/PageController");
var ESearchParams;
(function (ESearchParams) {
    ESearchParams["PAGE_INDEX"] = "pageIndex";
    ESearchParams["PAGE_SIZE"] = "pageSize";
    ESearchParams["FROM"] = "from";
    ESearchParams["TO"] = "to";
    ESearchParams["STATUS"] = "status";
    ESearchParams["QUERY"] = "query";
    ESearchParams["CUSTOMER_ID"] = "customerId";
    ESearchParams["ORDER_ID"] = "saleId";
    ESearchParams["INVOICE_ID"] = "invoiceId";
})(ESearchParams = exports.ESearchParams || (exports.ESearchParams = {}));
const getSearchParams = (searchParams, paymentStatusDropdown, accountDropdownsData, pageType) => {
    const isInvoicesPage = pageType === useMyOrdersPageData_1.EPageType.INVOICES;
    const params = Object.fromEntries(searchParams);
    const searchParamsToDispatch = {};
    Object.keys(params).forEach(key => {
        const value = params[key];
        switch (key) {
            case ESearchParams.PAGE_INDEX:
                searchParamsToDispatch.pageIndex = +value;
                break;
            case ESearchParams.QUERY:
                if (isInvoicesPage) {
                    searchParamsToDispatch.invoiceNumber = value;
                }
                else {
                    searchParamsToDispatch.orderNumber = value;
                }
                break;
            case ESearchParams.FROM:
                searchParamsToDispatch.fromDate = new Date(value);
                break;
            case ESearchParams.TO:
                searchParamsToDispatch.toDate = new Date(value);
                break;
            case ESearchParams.PAGE_SIZE:
                {
                    const index = PageController_1.OPTIONS_PER_PAGE.findIndex(option => option.name === value);
                    if (index > 0) {
                        searchParamsToDispatch.pageSize = PageController_1.OPTIONS_PER_PAGE[index];
                    }
                }
                break;
            case ESearchParams.STATUS:
                if (isInvoicesPage) {
                    const index = paymentStatusDropdown.findIndex(option => option.name === value);
                    if (index > 0) {
                        searchParamsToDispatch.selectedPaymentStatusOption = paymentStatusDropdown[index];
                    }
                }
                break;
            case ESearchParams.CUSTOMER_ID:
                {
                    const index = accountDropdownsData.findIndex(option => option.name.includes(value));
                    if (index > 0) {
                        searchParamsToDispatch.selectedAccountOption = accountDropdownsData[index];
                    }
                }
                break;
        }
    });
    return searchParamsToDispatch;
};
exports.getSearchParams = getSearchParams;
